import React from 'react';
import { Link } from 'react-router-dom';

const DZText = ({
  children,
  className,
  style,
  big,
  normal,
  paragraph,
  small,
  mobile,
  onClick,
  link,
  sideLink,
  sideLinkActive
}) => {
  const Element = link ? Link : 'span'; // Determine which element to render

  return (
    <>
      <Element
        className={`
          ${className}
          ${big && 'text-[36px] font-[600]'}
          ${normal && 'text-[24px] font-[500]'}
          ${paragraph && 'text-[14px] font-[500]'}
          ${small && 'text-[16px] font-[400]'}
          ${mobile && 'text-[14px] font-[400]'}
          ${link && 'cursor-pointer'}
          ${sideLink && 'before:absolute before:start-[-16px] before:w-[8px] before:top-[7.5px] before:h-[8px] before:rounded-full transition'}
          ${sideLinkActive && 'before:absolute before:start-[-16px] before:w-[8px] before:top-[7.5px] before:h-[8px] before:rounded-full before:bg-[#112D4E] ml-[16px]'}
        `}
        to={link}
        onClick={onClick}
        style={style}
      >
        {children}
      </Element>
    </>
  );
};

export default DZText;
