import React, { useEffect, useState } from 'react'
import ViewTranslation from '../../components/views/ViewTranslation';
import ViewUiBox from '../../components/views/ViewUiBox'
import { useLanguagePageManager } from '../../lib/customHooks/useLanguagePageManager'
import { RightArrowIcon } from '../../icons/Icons';
import DZModal from '../../components/modal/DZModal';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { EmptyLoader, InfinityLoader, LangSaver, Loading } from '../../components/loader/Loader';
import { calculatePercentage } from '../../lib/functions';
import PercentageBar from '../../components/percentageBar/PercentageBar';
import { TRANSLATION_TYPES } from '../../constants/Index';
import { useGetUserRole } from '../../lib/customHooks/useGetUserRole';
import { useLocation } from 'react-router-dom';
import MetaTags from '../../components/seo/MetaTags';
import { useNavigationManager } from '../../lib/customHooks/useNavigationManager';

const LanguagePage = () => {

    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const { allFiles, allProjectFtps, allFilesDropDown, downloadFileId, exportFileId, fileName, userRole, format, downloadModal, exportModal, filteredKeys, keys, projectId, projectName, selectedServerId,
        isProjectPersonal, languageName, searchTerm, selectedFile, translationsForLanguage, selectedLangId, projectLanguages, isLoading,
        isLoadingAddTranslation, isLoadingUpdateTranslation, isLoadingFileDeploy, isLoadingDownloadTranslation, exportFileLoading,
        fileFtpDetail, isLoadingFileFtpDetail, isFetchingFileFtpDetail,
        setDownloadFileId, setExportFileId, setDownloadModal, setExportModal, setSelectedLangId, setFileName, setFormat, setSelectedServerId,
        fnAddTranslation, fnDownloadTranslations, fnOnClickFile, fnOnSearch, fnUpdateTranslation, fnOnClickDownload, fnExportFile, fnOnClickExport } = useLanguagePageManager();

    const { fnGoBack } = useNavigationManager();

    const [showTranslations, setShowTranslations] = useState(TRANSLATION_TYPES.all);
    const { isUserTranslator } = useGetUserRole(userRole);

    const isExistKeys = filteredKeys?.length > 0;
    const totalKeysLength = keys?.filter((key) => key?.parent_id)?.length;
    const allExistTranslations = translationsForLanguage && translationsForLanguage?.length;

    const percentage = translationsForLanguage && (keys ? calculatePercentage(allExistTranslations, totalKeysLength, 0) : <InfinityLoader loaderStyle={{ height: '29px', maxWidth: '250px', }} />);

    const allFormatOptions = [
        { formatName: "JSON (.json)", value: 'json' },
        { formatName: "Key-Value JSON (.json)", value: 'json' },
        { formatName: "i18next JSON (.json)", value: 'json' },
        { formatName: "PHP Array (.php)", value: 'php' },
        { formatName: "Flutter ARB (.arb)", value: 'arb' },
        { formatName: "CSV (.csv)", value: 'csv' },
        { formatName: "Java Properties (.properties)", value: 'properties' },
        { formatName: ".NET RESW (.resw)", value: 'resw' },
        { formatName: ".NET RESX (.resx)", value: 'resx' },
        { formatName: "Qt TS (.ts)", value: 'ts' },
        { formatName: "iOS Strings (.strings)", value: 'strings' },
        { formatName: "Angular XMB (.xmb)", value: 'xmb' },
        { formatName: "Angular XTB (.xtb)", value: 'xtb' },
        { formatName: "Android String Resources (.xml)", value: 'xml' },
        { formatName: "YAML (.yml)", value: 'yml' }
    ];

    useEffect(() => {
        if (allFiles && allFiles?.length > 0) {
            setDownloadFileId(allFiles[0]?.id);
            setExportFileId(allFiles[0]?.id);
            setFileName(allFiles[0]?.name);
        }

        const fnKeyDown = (event) => {
            if (event?.key === 'ArrowUp') {
                event?.preventDefault();
                setSelectedIndex((prevIndex) => Math?.max(prevIndex - 1, 0));
            } else if (event?.key === 'ArrowDown') {
                event?.preventDefault();
                setSelectedIndex((prevIndex) => Math?.min(prevIndex + 1, allFiles?.length - 1));
            }
        };

        window?.addEventListener('keydown', fnKeyDown);

        return () => {
            window?.removeEventListener('keydown', fnKeyDown);
        };
    }, [allFiles]);

    useEffect(() => {
        setSelectedServerId(allProjectFtps?.[0]?.id)
    }, [allProjectFtps]);

    useEffect(() => {
        if (allFiles && allFiles?.length > 0) {
            setDownloadFileId(allFiles[selectedIndex]?.id);
            setExportFileId(allFiles[selectedIndex]?.id);
            setFileName(allFiles[selectedIndex]?.name);
        }
    }, [selectedIndex, allFiles]);

    return (
        <div className="flex items-center justify-center my-[6vw]">

            <MetaTags href={location.pathname} pageName={`Byte Translate | ${projectName ?? 'Project Translations'}`} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

            <div className='flex flex-col items-center gap-[24px] max-[1100px]:px-[6vw] w-full px-[16vw]'>

                {projectName && (
                    <div className="flex items-center justify-between w-full">

                        <div onClick={() => fnGoBack()} className="flex items-center gap-[20px] w-fit max-w-[1200px] cursor-pointer group">

                            <RightArrowIcon className={'cursor-pointer w-[32px] h-[32px] group-hover:translate-x-[-10px] transition'} iconColor={'var(--Primary)'} />

                            <span className='text-[var(--Primary)] text-[24px] w-fit'>
                                {projectName}
                            </span>

                        </div>

                        {isProjectPersonal ?
                            <select onChange={(e) => setSelectedLangId(e.target.value)} >
                                {projectLanguages?.map((language) => {
                                    const langId = language?.lang?.id;
                                    const langName = language?.lang?.name;
                                    return (
                                        <option value={langId} selected={langId == selectedLangId} >{langName}</option>
                                    )
                                })}
                            </select>
                            : <h3>{languageName}</h3>
                        }

                    </div>
                )}

                <div style={{ width: '100%' }}>
                    <PercentageBar percentage={percentage} />
                </div>

                <ViewUiBox className="w-full" showHeader={false} topSpacing={true}>

                    <input className='input__field' placeholder='Search word' value={searchTerm} onChange={(event) => fnOnSearch(event)} style={{ maxWidth: '100%', minWidth: '100%' }} />

                    <div className="flex items-start gap-[10px] flex-col">

                        <span className="text-[#333] font-[500] text-nowrap">
                            Select File
                        </span>

                        <select className='input__field' style={{ width: '100%' }} onChange={(e) => fnOnClickFile(e.target.value)} value={selectedFile} >
                            {allFilesDropDown?.map((file) => <option value={file?.id} selected={file?.id == selectedFile} >{file?.name}</option>)}
                        </select>

                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: "end", alignItems: 'end' }}>

                        <select onChange={(e) => setShowTranslations(e.target.value)} style={{ margin: '0px 12px' }}>

                            <option value={TRANSLATION_TYPES.all} selected={showTranslations == TRANSLATION_TYPES.all} >All Translations</option>
                            <option value={TRANSLATION_TYPES.missing} selected={showTranslations == TRANSLATION_TYPES.missing} >Missing Translations</option>

                        </select>

                        {translationsForLanguage ? (
                            <div> {totalKeysLength} / {allExistTranslations}&nbsp;&nbsp;&nbsp;&nbsp;  </div>
                        ) : (
                            <div > <LangSaver loaderStyle={{ height: '30px' }} /> </div>
                        )}


                        <div style={{ display: 'flex', gap: 12 }}>
                            <h1 onClick={() => fnOnClickDownload()} style={{ textDecoration: 'underline', cursor: 'pointer', }} >{'download'}</h1>
                            {!isUserTranslator && <h1 onClick={() => fnOnClickExport()} style={{ textDecoration: 'underline', cursor: 'pointer', }} >{'publish'}</h1>}
                        </div>
                    </div>

                    <p className='text-[12px] text-[red] ml-[218px]' >
                        please don't change any variable which shown with semicolon and brackets like <b>Dear :name</b> and <b>{'Dear {{name}}'}</b>, here is <b>name</b> not need to change, because it is a variable
                    </p>

                    <div className="flex flex-col gap-[20px] overflow-y-scroll max-h-[60dvh]">


                        {
                            isLoading ? <Loading />
                                : isExistKeys ?
                                    filteredKeys?.map((keyData, i) => (
                                        <ViewTranslation
                                            key={i}
                                            index={i}
                                            keyData={keyData}
                                            allFiles={allFiles}
                                            fnAddTranslation={fnAddTranslation}
                                            fnUpdateTranslation={fnUpdateTranslation}
                                            translationsForLanguage={translationsForLanguage}
                                            projectId={projectId}
                                            isLoading={isLoadingAddTranslation || isLoadingUpdateTranslation}
                                            showTranslations={showTranslations}
                                        />
                                    ))
                                    : <EmptyLoader />
                        }

                    </div>

                </ViewUiBox>

                <DZModal open={downloadModal} onClose={() => { setDownloadModal(false); setDownloadFileId(null); setFileName('') }}>

                    <select value={format} onChange={(e) => setFormat(e.target.value)} style={{ margin: '20px 0px ' }}>
                        <option hidden>Select Format</option>
                        {allFormatOptions?.map((format) => <option value={format?.value} >{format?.formatName}</option>)}
                    </select>

                    <h1>Select any File</h1>

                    {allFiles?.map((file) => {
                        return (
                            <>
                                <h1
                                    key={file?.id}
                                    onClick={() => { setDownloadFileId(file?.id); setFileName(file?.name) }}
                                    style={{
                                        borderWidth: file?.id === downloadFileId ? '1px' : '0',
                                        marginTop: '2%',
                                        cursor: 'pointer',
                                        borderColor: 'var(--Primary)',
                                        padding: '4px',
                                        borderRadius: '6px',
                                    }}
                                >
                                    {file?.name}
                                </h1>
                            </>
                        )
                    })}

                    <DZBtn onClick={() => fnDownloadTranslations()} btnStyle={{ marginTop: '20%', }} >
                        {isLoadingDownloadTranslation ? 'Loading...' : 'Download File'}
                    </DZBtn>

                </DZModal>

                <DZModal open={exportModal} modalStyle={{ display: 'flex', flexDirection: 'column', gap: '12px' }} onClose={() => { setExportModal(false); setExportFileId(null); setFileName('') }} >

                    {
                        isLoadingFileFtpDetail || isFetchingFileFtpDetail ? <Loading containerStyle={{ minHeight: '350px' }} />
                            : <>
                                <h1>Select your file and server to publish your file</h1>

                                <select onChange={(e) => { setExportFileId(e.target.value); }} style={{ padding: '14px 8px', background: 'transparent', border: '1px solid lightgrey', borderRadius: '4px' }} >
                                    <option hidden >Select Any File</option>
                                    {allFiles?.map((file) => <option value={file?.id} selected={exportFileId == file?.id} onClick={() => setFileName(file?.name)}> {file?.name} </option>)}
                                </select>

                                <select onChange={(e) => { setSelectedServerId(e.target.value); }} style={{ padding: '14px 8px', background: 'transparent', border: '1px solid lightgrey', borderRadius: '4px' }} >
                                    <option hidden >Select Server</option>
                                    {allProjectFtps?.map((server) => <option value={server?.id} selected={selectedServerId == server?.id}> {server?.deployment_name} </option>)}
                                </select>

                                <h1>Selected Format</h1>
                                <p style={{ padding: '14px 8px', background: 'transparent', border: '1px solid lightgrey', borderRadius: '4px' }} >
                                    {allFormatOptions?.find((f) => fileFtpDetail?.data?.file_ftp?.default_export?.includes(f.value))?.formatName}
                                </p>

                                <DZBtn onClick={() => fnExportFile()} btnStyle={{ marginTop: '10%', }} >
                                    {isLoadingFileDeploy ? 'Loading...' : 'Publish File'}
                                </DZBtn>
                            </>
                    }

                </DZModal>

            </div>
        </div>
    )
}

const styles = {
    btn: { backgroundColor: 'red', padding: 5, margin: 5, color: 'white', borderRadius: 10, width: 80, textAlign: 'center', cursor: 'pointer' },
    keyBox: { display: 'flex', flexDirection: 'row', backgroundColor: 'pink', justifyContent: 'space-between', margin: 10, padding: 10 },
    input: { width: 400 },
    inputBox: { paddingRight: '20%' },
    searchInput: { backgroundColor: 'lightgray', width: '100%', padding: 10 }
}

export default LanguagePage
