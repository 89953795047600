import React, { useEffect, useRef, useState } from 'react'
import './Navbar.css'

import { Link, useLocation } from 'react-router-dom'

import Logout from '../../../pages/logout/Logout'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../reactRoute/RouteConstants'

const Navbar = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const mobileBar = useRef(null);

  const isDashBoardPage = location.pathname == ROUTES.dashboard;
  const isContributorPage = location.pathname == ROUTES.contributor;
  const isProfilePage = location.pathname == ROUTES.profile;

  const [isShowMobileBar, setIsShowMobileBar] = useState(false);

  useEffect(() => {
    const handClickOutside = (event) => {
      if (mobileBar.current && !mobileBar.current.contains(event.target)) {
        setIsShowMobileBar(false);
      }
    };

    document.addEventListener("mousedown", handClickOutside);
    return () => { document.removeEventListener("mousedown", handClickOutside); };

  }, []);

  const MobileNavbar = () => {
    return (
      <div className="flex items-center justify-center inset-0 fixed z-[9] bg-[#11111130]">
        <div className="flex items-center flex-col  justify-center bg-[var(--primary-100)] p-[20px] rounded-[20px]" ref={mobileBar}>
          <span onClick={() => { navigate(ROUTES.dashboard); setIsShowMobileBar(false) }} className={`text-[16px] text-[var(--Primary)] cursor-pointer p-[20px] rounded-[20px] ${isDashBoardPage && "bg-[var(--primary-700)] text-[white]"}`}>
            Dashboard
          </span>

          <span onClick={() => { navigate(ROUTES.contributor); setIsShowMobileBar(false) }} className={`text-[16px] text-[var(--Primary)] cursor-pointer p-[20px] rounded-[20px] ${isContributorPage && "bg-[var(--primary-700)] text-[white]"}`}>
            Contributors
          </span>

          <span onClick={() => { navigate(ROUTES.profile); setIsShowMobileBar(false) }} className={`text-[16px] text-[var(--Primary)] cursor-pointer p-[20px] rounded-[20px] ${isProfilePage && "bg-[var(--primary-700)] text-[white]"}`}>
            Profile
          </span>
        </div>
       </div>
    )
  };

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center',padding: 20 }}>
    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',  borderRadius: 10, width:"98%", padding:22}} className="navbar__anime bg-[var(--primary-100)]">
      <div onClick={() => navigate(ROUTES.home)}>
        <img src="images/logo.png" className="cursor-pointer" alt="" />
      </div>
      <div  className='flex items-center gap-[35px] max-lg:hidden'>

        <Link to={ROUTES.dashboard} className={`text-[16px] text-[var(--primary-700)] cursor-pointer ${isDashBoardPage && "underline"}`}>
          Dashboard
        </Link>

        <Link to={ROUTES.contributor} className={`text-[16px] text-[var(--primary-700)] cursor-pointer ${isContributorPage && "underline"}`}>
          Contributors
        </Link>

        <Link to={ROUTES.profile} className={`text-[16px] text-[var(--primary-700)] cursor-pointer ${isProfilePage && "underline"}`}>
          Profile
        </Link>

      </div>

      <div className='flex justify-center align-center' >
      <svg onClick={() => setIsShowMobileBar(true)} className='lg:hidden cursor-pointer mr-[20px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"var(--Primary)"} fill={"none"}>
            <path d="M10 5L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 12L20 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 19L14 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <Logout />
      </div>

    </div >
      {isShowMobileBar && <MobileNavbar />}
</div>
  )
}

export default Navbar