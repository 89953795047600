import Lottie from "react-lottie"
import emptyDataAnimation from '../../animations/loading.json'
import searchLoader from '../../animations/searchLoader.json'
import lang_saver from '../../animations/lang_saver.json';
import infinity_loader from '../../animations/infinity_loader.json'
import notFound from '../../animations/notFound.json'
import './Loader.css';

export const EmptyLoader = ({ loaderStyle, containerClassName }) => {
  const mergeStyles = { height: "300px", width: "300px", ...loaderStyle }
  return (
    <div className={containerClassName} style={{ display: 'flex', minHeight: '400px', alignItems: 'center' }} >
      <Lottie options={{ animationData: notFound }} style={mergeStyles} />
    </div>
  )
};
export const Loading = ({ loaderStyle, containerClassName, containerStyle }) => {
  const mergeStyles = { height: "200px", width: "200px", ...loaderStyle };
  return (
    <div className={containerClassName} style={{ display: 'flex', minHeight: '400px', alignItems: 'center', ...containerStyle }}>
      <Lottie options={{ animationData: searchLoader }} style={mergeStyles} />
    </div>
  )
};
export const LangSaver = ({ loaderStyle, containerClassName }) => {
  const mergeStyles = { height: "50px", width: "50px", ...loaderStyle };
  return (
    <Lottie options={{ animationData: lang_saver }} style={mergeStyles} />
  )
};

export const InfinityLoader = ({ loaderStyle}) => {
  return (
    <Lottie options={{ animationData: infinity_loader, }} style={{ maxWidth: "320px", zIndex: 1, "@media (max-width: 1100px)": { maxWidth: "200px", }, ...loaderStyle}} />
  )
}

export const CircleLoader =()=>{
  return (
    <div className="circle_loader">
      <div className="circle_loader_child" ></div>
    </div>
  )
}