import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAddProjectFtpMutation, useDeleteProjectFtpMutation, useGetProjectFtpQuery, useUpdateProjectFtpMutation } from '../../redux/storeApis';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { META_TAGS, Server_Types, SNACK_BAR_MSG } from '../../constants/Index';
import DZModal from '../../components/modal/DZModal';
import { TextField } from '@mui/material';
import DZBtn from '../../components/shared/buttons/DZBtn';
import ViewUiBox from '../../components/views/ViewUiBox';
import { DeleteIcon, EditIcon } from '../../icons/Icons';
import MetaTags from '../../components/seo/MetaTags';

const SettingsPage = () => {

    const location = useLocation();
    const { fnShowSnackBar } = useSnackBarManager();

    const selectedProjectId = location.state.projectId;

    const [addProjectFtp, { isLoading : isLoadingAddProjectFtp }] = useAddProjectFtpMutation();
    const [updateProjectFtp, { isLoading : isLoadingUpdateProjectFtp }] = useUpdateProjectFtpMutation();
    const [deleteProjectFtp, { isLoading : isLoadingDeleteProjectFtp }] = useDeleteProjectFtpMutation();
    const { data : getAllProjectFtps } = useGetProjectFtpQuery(selectedProjectId);

    const allProjectFtps = getAllProjectFtps?.data?.project_ftps;

    const [settingModal, setSettingModal] = useState(false);
    const [settingsInputData, setSettingsInputData] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: null });

    const [selectedProjectFtpData, setSelectedProjectFtpData] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const fnOnChangeSettingsInput = useCallback((e)=> {
        const {name, value} = e.target;
        setSettingsInputData((pre) => ({...pre, [name] : value }));
    },[]);

    const fnOnChangeEditsInput = useCallback((e)=> {
        const {name, value} = e.target;
        setSelectedProjectFtpData((pre) => ({...pre, [name] : value }));
    },[]);

    const fnAddProjectFtp = useCallback(()=> {
        const checkValidation = settingsInputData?.deployment_name && settingsInputData?.sftp_username && settingsInputData?.sftp_server && settingsInputData?.sftp_password && settingsInputData?.server_type && settingsInputData?.project_id;
 
        if (checkValidation) {
         addProjectFtp(settingsInputData)?.unwrap()?.then((payload) => {
             if (payload?.success) {
                 setSettingModal(false);
                 fnShowSnackBar('Project Ftp is Added!');
             }
         }
         ).catch((error) => {
             fnShowSnackBar(SNACK_BAR_MSG.ERROR)
         });
        } else {
         fnShowSnackBar('Please must fill all the fields!')
        }
 
    },[settingsInputData]);

    const fnUpdateProjectFtp = useCallback(()=> {
        const checkValidation = selectedProjectFtpData?.deployment_name && selectedProjectFtpData?.sftp_username && selectedProjectFtpData?.sftp_server && selectedProjectFtpData?.sftp_password && selectedProjectFtpData?.server_type && selectedProjectFtpData?.project_id;
        const body = {
         id : selectedProjectFtpData?.id,
         deployment_name : selectedProjectFtpData?.deployment_name,
         sftp_username : selectedProjectFtpData?.sftp_username,
         sftp_server : selectedProjectFtpData?.sftp_server,
         sftp_password : selectedProjectFtpData?.sftp_password,
         project_id : selectedProjectFtpData?.project_id,
         server_type : selectedProjectFtpData?.server_type,
         
        };
 
        if (checkValidation) {
         updateProjectFtp(body)?.unwrap()?.then((payload) => {
             if (payload?.success) {
                 setEditModal(false);
                 fnShowSnackBar('Project Ftp is Updated!');
             }
         }
         ).catch((error) => {
             fnShowSnackBar(SNACK_BAR_MSG.ERROR)
         });
        } else {
         fnShowSnackBar('Please must fill all the fields!')
        }
 
    },[selectedProjectFtpData]);

    const fnDeleteProjectFtp = useCallback(()=> {
        deleteProjectFtp(deleteModal?.id)?.unwrap()?.then((payload) => {
            if (payload?.success) {
                setDeleteModal(false);
                fnShowSnackBar('Project Ftp is Removed Successfully!');
            }
        }
        ).catch((error) => {
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });
    },[deleteModal]);

  return (
    <ViewUiBox projectName={'Project Ftps'} onClickAddBtn={()=> {setSettingModal(true); setSettingsInputData({...settingsInputData, project_id : selectedProjectId })}}>

        <MetaTags href={location.pathname} pageName={META_TAGS.project_fps} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

        {
            allProjectFtps?.map((ftp)=>{
                return (
                    <>
                        <div className='project__div' >
                            <h3 className="project__heading">
                                {ftp?.deployment_name}                       
                            </h3>

                            <div className="flex items-center gap-[24px]">
                                <div className="add__btn" onClick={()=> { setSelectedProjectFtpData(ftp); setEditModal(true); }} >
                                    <EditIcon />
                                </div>
                                <div className="add__btn" onClick={()=> { setDeleteModal({id : ftp?.id, status:true}); }} >
                                    <DeleteIcon />
                                </div>
                            </div>

                        </div>
                    </>
                )
            })
        }

        <DZModal modalStyle={{gap:12, display : 'flex', flexDirection : 'column'}} open={settingModal} onClose={()=> setSettingModal(false)} >
            <select name='server_type' onChange={fnOnChangeSettingsInput} style={{ color : '#666263', padding : '14px 8px', background:'transparent', border : '1px solid lightgrey', borderRadius: '4px'}} >
                <option value={''} hidden>Server Type</option>
                <option value={Server_Types.sftp} >sftp server</option>
                <option value={Server_Types.ftp} >ftp server</option>
            </select>
            <TextField name='deployment_name' id="outlined-basic" label='Deployment Name' variant="outlined" onChange={fnOnChangeSettingsInput} style={{ width: '100%' }} />
            <TextField name='sftp_username' id="outlined-basic" label='SFTP User Name' variant="outlined" onChange={fnOnChangeSettingsInput} style={{ width: '100%' }} />
            <TextField name='sftp_server' id="outlined-basic" label='SFTP Server' variant="outlined" onChange={fnOnChangeSettingsInput} style={{ width: '100%' }} />
            <TextField name='sftp_password' id="outlined-basic" label='SFTP Password' variant="outlined" onChange={fnOnChangeSettingsInput} style={{ width: '100%' }} />
            <DZBtn onClick={fnAddProjectFtp} btnStyle={{ marginTop: '10%', width: '100%', fontSize: 13, }} variant="contained">
                { isLoadingAddProjectFtp ? 'Loading...' : 'Add'}
            </DZBtn>
        </DZModal>

        <DZModal modalStyle={{gap:12, display : 'flex', flexDirection : 'column'}} open={editModal} onClose={()=> setEditModal(false)} >
    <select name='server_type' value={selectedProjectFtpData?.server_type} onChange={fnOnChangeEditsInput} style={{ color : '#666263', padding : '14px 8px', background:'transparent', border : '1px solid lightgrey', borderRadius: '4px'}} >
                <option value={''} hidden>Server Type</option>
                <option selected={selectedProjectFtpData?.server_type == Server_Types.sftp} value={Server_Types.sftp} >sftp server</option>
                <option selected={selectedProjectFtpData?.server_type == Server_Types.ftp} value={Server_Types.ftp} >ftp server</option>
            </select>
            <TextField placeholder='Deployment Name' value={selectedProjectFtpData?.deployment_name} name='deployment_name' id="outlined-basic" variant="outlined" onChange={fnOnChangeEditsInput} style={{ width: '100%' }} />
            <TextField  placeholder='Sftp Username' value={selectedProjectFtpData?.sftp_username} name='sftp_username' id="outlined-basic" variant="outlined" onChange={fnOnChangeEditsInput} style={{ width: '100%' }} />
            <TextField placeholder='Sftp Server' value={selectedProjectFtpData?.sftp_server} name='sftp_server' id="outlined-basic" variant="outlined" onChange={fnOnChangeEditsInput} style={{ width: '100%' }} />
            <TextField placeholder='Sftp Password' value={selectedProjectFtpData?.sftp_password} name=' sftp_password' id="outlined-basic" variant="outlined" onChange={fnOnChangeEditsInput} style={{ width: '100%' }} />

        

            <DZBtn onClick={fnUpdateProjectFtp} btnStyle={{ marginTop: '10%', width: '100%', fontSize: 13, }} variant="contained">
                { isLoadingUpdateProjectFtp ? 'Loading...' : 'Update'}
            </DZBtn>

        </DZModal>

        <DZModal open={deleteModal.status} disabled={isLoadingDeleteProjectFtp} onKeyDown={fnDeleteProjectFtp} onClose={() => setDeleteModal({ id: null, status: false })} >
            <h1>{'Are you sure to want to delete project Ftp ?'}</h1>
            <DZBtn disabled={isLoadingDeleteProjectFtp} onClick={fnDeleteProjectFtp} variant="contained">
                {isLoadingDeleteProjectFtp ? 'Loading...' : 'Delete Ftp'}
            </DZBtn>
        </DZModal>

    </ViewUiBox>
  )
}

export default SettingsPage