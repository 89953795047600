import React, { useState } from "react";
import { Config } from "../../constants/Index";
import { setLocalStorage } from "../../components/localStorage";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { useDispatch } from "react-redux";
import DZInput from "../../components/shared/inputField/DZInput";
import DZBtn, { DZOldBtn } from "../../components/shared/buttons/DZBtn";
import { useAddLoginUserMutation } from "../../redux/storeApis";
import { Navigate, useNavigate } from "react-router";
import { useUserManager } from "../../lib/customHooks/useUserManager";
import { ROUTES } from "../../reactRoute/RouteConstants";
import './InvitationResponse.css'

const InvitationResponse = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const { isLoggedInUser } = useUserManager();
  const [addLoginUser, { isLoading }] = useAddLoginUserMutation();

  const navigate = useNavigate();

  const isValidEmail = (email) => {
    return email.includes("@");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    if (!data.email || !data.password) {
      console.log("Validation failed: Email and password are required.");
      return;
    }

    try {
      const response = await addLoginUser(data).unwrap(); // Call the mutation function
      console.log("response", response)

      if (response?.success) {
        const fetchUserToken = response?.data?.access_token;
        if (fetchUserToken) {
          console.log("ok");
          setLocalStorage(Config.userToken, fetchUserToken);
          dispatch(setSelectedLoginUser(fetchUserToken));
          console.log("User successfully logged in!");

          navigate(ROUTES.home);
        } else {
          console.error("Login failed: Access token not found in response.");
        }
      } else {
        console.error("Login failed: ", response.message);
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };
  if (isLoggedInUser) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">


      <div className="login__form">

        <div className="form__heading">
          Welcome to <span>Byte</span>
        </div>

        <div>
          Please accept invitaion
        </div>

        <div className="form__fields">
          <DZInput id="email" name="email" type="email" placeholder="Enter your email" value={data.email} onChange={handleInputChange} className={'input__field'} />
          <DZInput id="password" name="password" type="password" placeholder="Enter your password" value={data.password} onChange={handleInputChange} className={'input__field'} />
        </div>

        <div onClick={() => navigate(ROUTES.forgetPassword)} className="forget__remember">
          <span className="form__text">Forget Password ?</span>
        </div>

        {/* <DZOldBtn toolTipText={"Click here to login"} textStyle={"text-F9F7F7 cursor-pointer"} toolTipPlacement={"bottom"} className={'form__btn'} children={"Login"} onClick={handleLogin} /> */}
        <DZBtn btnStyle={{ marginTop: 0, width: '100%',borderRadius: 16,height:50, fontSize: 20, }} children={"Login"} onClick={handleLogin} />
        <span onClick={() => navigate(ROUTES.signup)} className="form__text">
          Don’t have an account ? <span>Register</span>
        </span>

      </div>

    </div>
  );
};

export default InvitationResponse;
