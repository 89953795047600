import React, { useRef, useState } from "react";
import { Config, META_TAGS, SNACK_BAR_MSG } from "../../constants/Index";
import { setLocalStorage } from "../../components/localStorage";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { useDispatch } from "react-redux";
import DZInput from "../../components/shared/inputField/DZInput";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useAddLoginUserMutation } from "../../redux/storeApis";
import { useNavigate, useLocation } from "react-router";
import { ROUTES } from "../../reactRoute/RouteConstants";
import "./Login.css";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import Header from "../../components/header/Header";
import Facebook from "../SocialLogin/Facebook";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MetaTags from "../../components/seo/MetaTags";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [addLoginUser, { isLoading: isLoadingAddLoginUser }] =
    useAddLoginUserMutation();

  const { fnShowSnackBar } = useSnackBarManager();

  const [data, setData] = useState({ email: "", password: "" });

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const isValidEmail = (email) => {
    return email.includes("@");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleKeyDown = (e, inputType) => {
    if (e.key === "Enter") {
      if (inputType === "email") {
        passwordInputRef.current.focus();
      } else if (inputType === "password") {
        handleLogin(e);
      }
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    if (!data.email || !data.password) {
      setErrorMessage("⛔ Email and password are required.");
      return;
    }

    try {
      const response = await addLoginUser(data).unwrap();
      
      if (response?.success) {
        const fetchUserToken = response?.data?.access_token;
        if (fetchUserToken) {
          console.log("ok");
          setLocalStorage(Config.userToken, fetchUserToken);
          dispatch(setSelectedLoginUser(fetchUserToken));
          navigate(ROUTES.dashboard);
        } else {
          console.error("Login failed: Access token not found in response.");
          fnShowSnackBar(SNACK_BAR_MSG.ERROR);
        }
      } else {
        console.error("Login failed: ", response.message);
        fnShowSnackBar("wrong credentials, please try again!");
      }
    } catch (error) {
      console.error("Login failed:", error);
      fnShowSnackBar(SNACK_BAR_MSG.ERROR);
    }
  };


  const fnTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="bg-[#F3EDF7] overflow-x-hidden relative min-h-screen">
      <MetaTags href={location.pathname} pageName={META_TAGS.login} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />
      <Header />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="login__form">
          <div className="form__heading">
            Login to <span>Byte</span>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <div className="form__fields">
            <DZInput
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              value={data.email}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, "email")}
              className={"input__field"}
              ref={emailInputRef}
            />
            <DZInput
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={data.password}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, "password")}
              className={"input__field "}
              ref={passwordInputRef}
            />
              <button
                type="button"
                className="password_input_field"
                onClick={fnTogglePassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </button>


          </div>

          <div className="forget__remember">
            <span
              onClick={() => navigate(ROUTES.forgetPassword)}
              className="form__text"
            >
              Forget Password ?
            </span>
          </div>

          {/* <GoogleLogin
            onSuccess={credentialResponse => {
              console.log('credentialResponse=>>>>>>>', credentialResponse);
              const response = jwtDecode(credentialResponse.credential)
              if(response){
                console.log('response', response);
              }
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          /> */}

          {/* <DZOldBtn toolTipText={"Click here to login"} textStyle={"text-F9F7F7 cursor-pointer"} toolTipPlacement={"bottom"} className={'form__btn'} children={"Login"} onClick={handleLogin} /> */}
          <DZBtn
            btnStyle={{
              marginTop: 0,
              width: "100%",
              borderRadius: 16,
              height: 50,
              fontSize: 20,
            }}
            children={isLoadingAddLoginUser ? "Loading..." : "Login"}
            onClick={handleLogin}
          />
          {/* <Facebook /> */}

          <span className="form__text">
            Don’t have an account ?{" "}
            <span onClick={() => navigate(ROUTES.signup)}>Register</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
