import React, { useState } from "react";
import { Config } from "../../constants/Index";
import DZInput from "../../components/shared/inputField/DZInput";
import {DZOldBtn} from "../../components/shared/buttons/DZBtn";
import VerifyEmail from "../verifyEmail/VerifyEmail";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false); // Track if reset email is sent

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${Config.serverUrl}api/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("Reset email sent successfully.");
        setResetEmailSent(true); // Set resetEmailSent to true when email is sent successfully
      } else {
        setMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {resetEmailSent ? (
        <VerifyEmail email={email} />
      ) : (
        <>
          <div className="login__form">
            <div className="form__heading">
              <span>Forgot Password</span>
            </div>

            <div className="form__fields">
              <DZInput id="email" name="email" type="email" placeholder="Enter your email" value={email} className={'input__field'} onChange={handleInputChange} />
            </div>

            <DZOldBtn
              toolTipText={"Reset Password"}
              textStyle={"text-F9F7F7 cursor-pointer"}
              toolTipPlacement={"bottom"}
              onClick={handleSubmit}
              className={'form__btn'}
              children={loading ? "Loading..." : "Reset Password"}
              disabled={loading}
            />
            {message && (
              <p className="mt-4 text-center text-red-500">{message}</p>
            )}

          </div>

        </>
      )}
    </div>
  );
};

export default ForgotPassword;
