import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAddUserMutation } from "../../redux/storeApis";
import DZInput from "../../components/shared/inputField/DZInput";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useNavigate, useLocation } from "react-router";
import "./Signup.css";
import { ROUTES } from "../../reactRoute/RouteConstants";
import Header from "../../components/header/Header";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { META_TAGS } from "../../constants/Index";
import MetaTags from "../../components/seo/MetaTags";

const Signup = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setData({ name: "", email: "", password: "", password_confirmation: "" });
  }, []);

  const [addUser, { isLoading: isLoadingAddUser }] = useAddUserMutation();
  const { fnShowSnackBar } = useSnackBarManager();

  const navigate = useNavigate();
  const location = useLocation();

  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    fnShowSnackBar("");
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleKeyDown = (e, inputType) => {
    if (e.key === "Enter") {
      if (inputType === "name") {
        emailInputRef.current.focus();
      } else if (inputType === "email") {
        passwordInputRef.current.focus();
      } else if (inputType === "password") {
        confirmPasswordInputRef.current.focus();
      } else if (inputType === "password_confirmation") {
        handleSignup(e);
      }
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!data.email || !data.password || !data.name) {
      console.log("⛔ Name, Email and password are required.");
      fnShowSnackBar("⛔ Name, Email and password are required.");
      return;
    }
  
    if (data.password !== data.password_confirmation) {
      fnShowSnackBar("⛔ Confirm your password.");
      return;
    }
  
    if (data?.email && data?.password && data?.name) {
      try {
        const response = await addUser(data)?.unwrap();
        console.log("obj", response);
        if (response?.success) {
          console.log("response", response?.message);
          if (response.message === "Verification Code Send Successfully") {
            return navigate(`${ROUTES.confirmCode}`, {
              state: { email: data.email },
            });
          } else {
            return navigate(ROUTES.login);
          }
        } else {
          console.error("Code failed to send: ", response.message);
        }
      } catch (error) {
        if (error?.data?.errors?.email?.[0]) {
          console.log("error", error?.data?.errors);
          fnShowSnackBar("⛔ The email has already been taken.");
        } else {
          console.error("Signup failed:", error);
          fnShowSnackBar("Signup failed. Please try again later.");
        }
      }
    } else {
      console.log("Validation failed");
    }
  };
  

  const fnTogglePassword = (field) => {
    if (field == 'password') {
      setShowPassword(!showPassword);
    } else if (field == 'password_confirmation') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };


  return (
    <div className="bg-[#F3EDF7] overflow-x-hidden relative min-h-screen">

      <MetaTags href={location.pathname} pageName={META_TAGS.signup} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

      <Header />
      
      <div>
        <div className="login__form">
          <div className="form__heading">
            Sign up to <span>Byte</span>
          </div>

          {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}

          <div className="form__fields">
            <DZInput
              id="name"
              name="name"
              type="text"
              placeholder="Enter your name"
              className={"input__field"}
              value={data?.name}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, "name")}
              ref={nameInputRef}
            />

            <DZInput
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              className={"input__field"}
              value={data?.email}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, "email")}
              ref={emailInputRef}
            />

            <DZInput
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className={"input__field"}
              value={data?.password}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, "password")}
              ref={passwordInputRef}
            />

            <button
              type="button"
              className="password_field"
              onClick={() => fnTogglePassword('password')}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </button>

            <DZInput
              id="password_confirmation"
              name="password_confirmation"
              type={showConfirmPassword ? "text" : "password"}
              className={"input__field"}
              placeholder="Confirm your password"
              value={data?.password_confirmation}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, "password_confirmation")}
              ref={confirmPasswordInputRef}
            />
            <button
              type="button"
              className="password_inputField"
              onClick={() => fnTogglePassword('password_confirmation')}

            >
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </button>

          </div>

          <DZBtn
            btnStyle={{
              marginTop: 0,
              width: "100%",
              borderRadius: 16,
              height: 50,
              fontSize: 20,
            }}
            children={isLoadingAddUser ? "Loading...." : "Signup"}
            onClick={handleSignup}
          />
          <span className="form__text">
            Already have an account?{" "}
            <span onClick={() => navigate(ROUTES.login)}>Login</span>
          </span>
          <div style={{ marginBottom: 50 }}></div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
