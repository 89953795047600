import React from "react";

import { useNavigate } from "react-router";
import { useUserManager } from "../../lib/customHooks/useUserManager";
import { removeLocalStorage } from "../../components/localStorage";
import { useDispatch } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { Config, KEYS } from "../../constants/Index";
import { ROUTES } from "../../reactRoute/RouteConstants";

function Logout() {
  const { isLoggedInUser } = useUserManager();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fnLogoutUser = () => {
    removeLocalStorage(Config.userToken);
    removeLocalStorage(KEYS.selected_project_id);
    dispatch(setSelectedLoginUser(null));

    navigate(ROUTES.home);
  };

  const fnLogout = async () => {
    try {
      const response = await fetch(`${Config.serverUrl}api/logout`, {
        method: "GET",
        headers: { Authorization: `Bearer ${isLoggedInUser}` },
      });
      if (response?.status === 200) {
        fnLogoutUser();
      }
    } catch (error) {
      fnLogoutUser();
    } finally {
      fnLogoutUser();
    }
  };
  return (
    <div onClick={fnLogout} className="flex items-center gap-[10px] cursor-pointer group">

      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 20 20" fill="none">
        <path d="M13 15.625C12.9264 17.4769 11.3831 19.0494 9.31564 18.9988C8.83465 18.987 8.24013 18.8194 7.05112 18.484C4.18961 17.6768 1.70555 16.3203 1.10956 13.2815C1 12.723 1 12.0944 1 10.8373L1 9.16274C1 7.90561 1 7.27705 1.10956 6.71846C1.70555 3.67965 4.18961 2.32316 7.05112 1.51603C8.24014 1.18064 8.83465 1.01295 9.31564 1.00119C11.3831 0.95061 12.9264 2.52307 13 4.37501" stroke="var(--Primary)" stroke-width="1.5" stroke-linecap="round" />
        <path d="M19 10H8M19 10C19 9.29977 17.0057 7.99153 16.5 7.5M19 10C19 10.7002 17.0057 12.0085 16.5 12.5" stroke="var(--Primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>

      <span className='group-hover:translate-x-1 transition' style={{color: 'var(--Primary)'}}>
        Logout
      </span>

    </div>
  );
}

export default Logout;
