import React from 'react';
import './PercentageBar.css';

const PercentageBar = ({ percentage }) => {
  return (
    <div className="percentage-bar">
      <div
        className="percentage-bar__fill"
        style={{ width: `${percentage}%` }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default PercentageBar;
