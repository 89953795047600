import React, { useEffect, useRef, useState } from 'react'
import { InfinityLoader } from '../../components/loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';
import { Element, scroller, animateScroll as scroll, scrollSpy } from 'react-scroll';
import MetaTags from '../../components/seo/MetaTags';
import './feature.css'
import Header from '../../components/header/Header';
import { META_TAGS } from '../../constants/Index';

const Feature = () => {
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
   
  }, []);

  useEffect(() => {
   
  }, []);

  return (
    <div className="bg-[#F3EDF7] overflow-x-hidden relative min-h-screen">

      <MetaTags href={location.pathname} pageName={META_TAGS.features} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

      <Header />
      <Element name="Features">
      <div className='flex items-center justify-between px-[64px] pb-[64px] pt-[64px]  gap-[32px] max-[1100px]:flex-col max-sm:px-[24px] '>

        <div className="flex flex-col gap-[24px] max-w-[694px] max-[1100px]:items-center max-sm:gap-[16px]">

          <h1 className="heading1 text-[var(--neutral-700)] max-[1100px]:text-center">
            A wide range of <span className='text-[var(--primary-700)]'>translation options</span> are <span>available</span>
          </h1>

          <p className="text-[var(--neutral-700)] max-[1100px]:text-center">
            Unlock a world of linguistic possibilities with our extensive array of translation options. Whether you're looking to bridge language barriers in business communications, understand foreign literature, or connect with global audiences, our versatile suite of translation services has you covered
          </p>

          <div className="flex items-center gap-[20px]">

            <span onClick={() => navigate(ROUTES.login)} className="btn">
              Login
            </span>

          </div>

        </div>

        <img src="images/feature1.png" alt="" className='effect__pink rounded-[26px] max-[1426px]:max-w-[600px] max-[1240px]:max-w-[500px] max-[1100px]:max-w-full' />

      </div>
    </Element>
     
    </div>
  );
};

export default Feature;