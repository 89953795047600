
const serverUrl = process.env.REACT_APP_SERVER_URL;
const S3Url = process.env.REACT_APP_IMG_ENDPOINT;
const googleClientId=process.env.REACT_APP_GOOGLE_CLIENT_ID;
const facebookAppId=process.env.REACT_APP_FACEBOOK_APP_ID;

export const Config = {
    restaurantId: 1,
    facebookLoginAppId: '',

    serverUrl: serverUrl,
    serverApiUrl: serverUrl + 'api/',

    apiTokenName: 'byte-token',
    userToken: 'user-token',
    language: 'lang',
    googleApiKey: 'AIzaSyAGm9Qv2yhO03ggoPIogG3ny3dXsGZFIG0',
    googleClientId:googleClientId,
    facebookAppId:facebookAppId,
    currency_symbol: "DKK",
    currency: "Kr.",
    pushTokenName: 'device-push-token',

    // product image path
    linkProductSmallImg: S3Url + "products/small/",
    linkProductLargeImg: S3Url + "products/large/",
    linkProductMediumImg: S3Url + "products/medium/",
    // accessory image path
    linkAccessorySmallImg: S3Url + "products/small/",
    linkAccessoryLargeImg: S3Url + "products/large/",
    linkAccessoryMediumImg: S3Url + "products/medium/",
    // category paths
    linkCategorySmallImg: S3Url + "categories/small/",
    linkCategoryLargeImg: S3Url + "categories/large/",
    linkCategoryMediumImg: S3Url + "categories/medium/",
    // Admin image paths
    linkAdminSmallImg: S3Url + "admin/small/",
    linkAdminLargeImg: S3Url + "admin/large",
    linkAdminMediumImg: S3Url + "admin/medium",
    // User image paths
    linkUserSmallImg: S3Url + "user/small/",
    linkUserLargeImg: S3Url + "user/large/",
    linkUserMediumImg: S3Url + "user/medium/",

    // Restaurant image paths
    linkRestaurantSmallImg: S3Url + "shop/small/",
    linkRestaurantLargeImg: S3Url + "shop/large/",
    linkRestaurantMediumImg: S3Url + "shop/medium/",

    defaultAvatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png',
    defaultCategoryImage: 'https://ugc.futurelearn.com/uploads/images/4d/c9/header_4dc9321b-f608-4196-9fb7-02f6c0029a5f.jpg',
    defaultProductImage: 'https://ugc.futurelearn.com/uploads/images/4d/c9/header_4dc9321b-f608-4196-9fb7-02f6c0029a5f.jpg',
    defaultAccessoryImage: 'https://ugc.futurelearn.com/uploads/images/4d/c9/header_4dc9321b-f608-4196-9fb7-02f6c0029a5f.jpg',
    defaultRestaurantImage: 'https://ugc.futurelearn.com/uploads/images/4d/c9/header_4dc9321b-f608-4196-9fb7-02f6c0029a5f.jpg',

    getAvatar: function getAvatar(avatar) {
        return avatar ? (this.linkUserSmallImg + avatar) : this.defaultAvatar
    },
    getCategoryImage: function (image) {
        return image ? (this.linkCategorySmallImg + image) : this.defaultCategoryImage;
    },
    getProductImage: function (image) {
        return image ? (this.linkProductSmallImg + image) : this.defaultProductImage;
    },
    getAccessoryImage: function (image) {
        return image ? (this.linkAccessorySmallImg + image) : this.defaultAccessoryImage;
    },
    getRestaurantImage: function (image) {
        return image ? (this.linkRestaurantSmallImg + image) : this.defaultRestaurantImage;
    },
    showCurrency: function (amount) {
        return "DKK " + amount ?? 0;
    },
    findMaxNumber: function (arr) {
        if (arr.length === 0) {
            return 0; // Return 0 for an empty array
        }

        return Math.max(...arr);
    },
    findMinNumber: function (arr) {
        if (arr.length === 0) {
            return 0; // Return 0 for an empty array
        }

        return Math.min(...arr);
    },
    fnShowCurrency: function (amount) {
        return "DKK " + amount;
    },
    fnGetDefaultProductPrice: function (prod, productSize) {
        const prodPrices = prod?.prices;
        const defaultPriceObj = productSize == PRODUCT_SIZES.ALL ? prodPrices?.find((price) => price?.size?.default == 1)
            : prodPrices?.find((price) => price?.size?.title?.en == productSize);
        const prod_price = defaultPriceObj?.price ?? 0;
        return prod_price;
    },
    fnGetSortHighToLow: function (data, productSize) {
        return data?.slice()?.sort((a, b) => {
            const itemA = this.fnGetDefaultProductPrice(a, productSize);
            const itemB = this.fnGetDefaultProductPrice(b, productSize);
            if (itemA < itemB) { return 1 };
            if (itemB < itemA) { return -1 };
            return 0;
        });
    },
    fnGetSortLowToHigh: function (data, productSize) {
        return data?.slice()?.sort((a, b) => {
            const itemA = this.fnGetDefaultProductPrice(a, productSize);
            const itemB = this.fnGetDefaultProductPrice(b, productSize);
            if (itemA > itemB) { return 1 };
            if (itemB > itemA) { return -1 };
            return 0;
        });
    },
    fnGetSortAToZ: function (data) {
        return data?.slice()?.sort((a, b) => {
            const itemA = a?.title?.en?.toLowerCase();
            const itemB = b?.title?.en?.toLowerCase();
            if (itemA > itemB) { return 1 };
            if (itemB > itemA) { return -1 };
            return 0;
        });
    },
    fnGetSortZToA: function (data) {
        return data?.slice()?.sort((a, b) => {
            const itemA = a?.title?.en?.toLowerCase();
            const itemB = b?.title?.en?.toLowerCase();
            if (itemA < itemB) { return 1 };
            if (itemB < itemA) { return -1 };
            return 0;
        });
    },
    fnGetSortedArray: function ({ type = 'DEFAULT', data = [], productSize }) {
        switch (type) {
            case 'DEFAULT':
                return data;
            case 'A_TO_Z':
                return this.fnGetSortAToZ(data);
            case 'Z_TO_A':
                return this.fnGetSortZToA(data);
            case 'HIGH_TO_LOW':
                return this.fnGetSortHighToLow(data, productSize);
            case 'LOW_TO_HIGH':
                return this.fnGetSortLowToHigh(data, productSize);
            default:
                return data;
        }
    }
};

export const SORTING_TYPE = {
    DEFAULT: 'DEFAULT',
    A_TO_Z: 'A_TO_Z',
    Z_TO_A: 'Z_TO_A',
    HIGH_TO_LOW: 'HIGH_TO_LOW',
    LOW_TO_HIGH: 'LOW_TO_HIGH',
};

export const PRODUCT_SIZES = {
    ALL: 'All',
    SMALL: 'Small',
    MEDIUM: 'Medium',
    NORMAL: 'Normal',
};

export const KEYS = {
    prod_popup: 'prod_popup',
    lang: 'lang',
    sort_type: 'sort_type',
    selected_project_id: 'selected_project_id'
};

export const LANGUAGES = {
    en: 'en',
    da: 'da',
    tr: 'tr',
    ar: 'ar'
};

export const ORDER_TYPE = {
    PICK_UP: 'pickup',
    DELIVER: 'deliver'
};

export const SNACK_BAR_MSG = {
    ERROR: 'Some error occurred, please try again!',
};

export const ROLES = {
    translator:'translator',
    owner:'owner',
    admin:'admin'
};

export const PROJECT_TYPE = {
    invited:'invited',
    personal:'personal',
};

export const PAGE_URLS = {
    BASE_URL: "https://byte-translate.danzeetech.com/",  
};

export const TRANSLATION_TYPES = {
    all : 'all',
    missing : 'missing'
};

export const META_TAGS = {
    home : "Byte Translate | Home",
    about : "Byte Translate | About Us",
    features : "Byte Translate | Features",
    contact : "Byte Translate | Contact Us",
    login : "Byte Translate | Login",
    signup : "Byte Translate | Register",
    forgot_password : "Byte Translate | Forgot Password",
    dashboard : "Byte Translate | DashBoard",
    contributors : "Byte Translate | Contributors",
    profile : "Byte Translate | Profile",
    project_fps : "Byte Translate | Project Ftps"
}

export const Server_Types = {
    ftp : 'ftp',
    sftp : 'sftp'
}