import ReactRoute from './reactRoute/ReactRoute'
import './globals.css'
import { RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Snackbar from './components/shared/snackbar/Snackbar';


function App() {
  return (
    <>
      <GoogleOAuthProvider clientId="534682546471-mibad5691foribttnsfk0k2t8mc64ikd.apps.googleusercontent.com">
        <RouterProvider router={ReactRoute} />
        <Snackbar />
      </GoogleOAuthProvider>
    </>
  )
}

export default App;