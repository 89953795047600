import React from 'react'

export const EditIcon = ({ className, iconColor = "#FFF" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            className={className}
        >
            <path
                d="M13.0737 2.88545C13.8189 2.07808 14.1915 1.6744 14.5874 1.43893C15.5427 0.870759 16.7191 0.853091 17.6904 1.39232C18.0929 1.6158 18.4769 2.00812 19.245 2.79276C20.0131 3.5774 20.3972 3.96972 20.6159 4.38093C21.1438 5.37312 21.1265 6.57479 20.5703 7.5507C20.3398 7.95516 19.9446 8.33578 19.1543 9.09701L9.75063 18.1543C8.25288 19.5969 7.504 20.3182 6.56806 20.6837C5.63212 21.0493 4.6032 21.0224 2.54536 20.9686L2.26538 20.9613C1.63891 20.9449 1.32567 20.9367 1.14359 20.73C0.961503 20.5234 0.986362 20.2043 1.03608 19.5662L1.06308 19.2197C1.20301 17.4235 1.27297 16.5255 1.62371 15.7182C1.97444 14.9109 2.57944 14.2555 3.78943 12.9445L13.0737 2.88545Z"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M12 3L19 10"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M13 21L21 21"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}

export const EyeIcon = ({ className, iconColor = "#FFF" }) => {
    return (
        <svg className={className} width={22} height={22} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor}><path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"></path></svg>
    )
}

export const AddIcon = ({ className, iconColor = "#FFF" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            className={className}
        >
            <path
                d="M11 7V15M15 11L7 11"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
                stroke={iconColor}
                strokeWidth="1.5"
            />
        </svg>
    )
}

export const SaveIcon = ({ className, iconColor = "#FFF" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={22}
            viewBox="0 0 20 22"
            fill="none"
            className={className}
        >
            <path
                d="M1 10.8584C1 6.28199 1 3.99376 2.38674 2.54394C2.43797 2.49038 2.49038 2.43797 2.54394 2.38674C3.99376 1 6.28199 1 10.8584 1C11.943 1 12.4655 1.00376 12.9628 1.18936C13.4417 1.3681 13.8429 1.70239 14.6452 2.37099L16.8411 4.20092C17.9027 5.08561 18.4335 5.52795 18.7168 6.13266C19 6.73737 19 7.42833 19 8.81025V12C19 15.7497 19 17.6246 18.0451 18.9389C17.7367 19.3634 17.3634 19.7367 16.9389 20.0451C15.6246 21 13.7497 21 10 21C6.25027 21 4.3754 21 3.06107 20.0451C2.6366 19.7367 2.26331 19.3634 1.95491 18.9389C1 17.6246 1 15.7497 1 12V10.8584Z"
                stroke={iconColor}
                strokeWidth="1.5"
            />
            <path
                d="M5 13.5C5 15.1499 5 15.9749 5.43934 16.4874C5.87868 17 6.58579 17 8 17H12C13.4142 17 14.1213 17 14.5607 16.4874C15 15.9749 15 15.1499 15 13.5M5 13.5C5 11.8501 5 11.0251 5.43934 10.5126C5.87868 10 6.58579 10 8 10H12C13.4142 10 14.1213 10 14.5607 10.5126C15 11.0251 15 11.8501 15 13.5M5 13.5H7M15 13.5H13"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const DeleteIcon = ({ className, iconColor = "#FFF" }) => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={22}
            viewBox="0 0 20 22"
            fill="none"
            className={className}
        >
            <path
                d="M17.5 4.5L16.8803 14.5251C16.7219 17.0864 16.6428 18.3671 16.0008 19.2879C15.6833 19.7431 15.2747 20.1273 14.8007 20.416C13.8421 21 12.559 21 9.99274 21C7.42312 21 6.1383 21 5.17905 20.4149C4.7048 20.1257 4.296 19.7408 3.97868 19.2848C3.33688 18.3626 3.25945 17.0801 3.10461 14.5152L2.5 4.5"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M7 10.7349H13"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M8.5 14.6543H11.5"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M1 4.5H19M14.0555 4.5L13.3729 3.09173C12.9194 2.15626 12.6926 1.68852 12.3015 1.39681C12.2148 1.3321 12.1229 1.27454 12.0268 1.2247C11.5937 1 11.0739 1 10.0343 1C8.96864 1 8.43579 1 7.99549 1.23412C7.89791 1.28601 7.80479 1.3459 7.7171 1.41317C7.32145 1.7167 7.10044 2.20155 6.65842 3.17126L6.05273 4.5"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>

    )
}

export const FileIcon = ({ className, iconColor = "#FFF", onClick }) => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={22}
            viewBox="0 0 20 22"
            fill="none"
            className={className}
            onClick={onClick}
        >
            <path
                d="M1.5 9C1.5 5.22876 1.5 3.34315 2.7448 2.17157C3.98959 1 5.99306 1 10 1H10.7727C14.0339 1 15.6645 1 16.7969 1.79784C17.1214 2.02643 17.4094 2.29752 17.6523 2.60289C18.5 3.66867 18.5 5.20336 18.5 8.27273V10.8182C18.5 13.7814 18.5 15.2629 18.0311 16.4462C17.2772 18.3486 15.6829 19.8491 13.6616 20.5586C12.4044 21 10.8302 21 7.68182 21C5.88275 21 4.98322 21 4.26478 20.7478C3.10979 20.3424 2.19875 19.4849 1.76796 18.3979C1.5 17.7217 1.5 16.8751 1.5 15.1818V9Z"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M18.5 11C18.5 12.8409 17.0076 14.3333 15.1667 14.3333C14.5009 14.3333 13.716 14.2167 13.0686 14.3901C12.4935 14.5442 12.0442 14.9935 11.8901 15.5686C11.7167 16.216 11.8333 17.0009 11.8333 17.6667C11.8333 19.5076 10.3409 21 8.5 21"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 6H13"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 10H9"
                stroke={iconColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}

export const RightArrowIcon = ({ className, iconColor = "#FFF", onClick }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#d72684"} fill={"none"} className={className}>
            <path d="M4 6L4 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M8 12.0005L20 12.0005" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 8C12 8 8.00001 10.946 8 12C7.99999 13.0541 12 16 12 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};

export const SettingIcon = ({ className, iconColor = "#FFF", onClick }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} color={iconColor} onClick={onclick} className={className} viewBox="0 0 24 24" fill="currentColor"><path d="M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.65311V16.3469L12 20.689L19.5 16.3469V7.65311L12 3.311ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"></path></svg>
    )
};