import React from 'react'
import { useLocation } from 'react-router-dom';
import { Element, scroller, animateScroll as scroll, scrollSpy } from 'react-scroll';
import MetaTags from '../../components/seo/MetaTags';
import './about.css'
import Header from '../../components/header/Header';
import { META_TAGS } from '../../constants/Index';


const About = () => {

  const location = useLocation();

  console.log('AboutPage rendered');

  const description = `
  Your go-to destination for seamless and comprehensive language translation services. At Byte translate, we're dedicated to breaking down language barriers and fostering global communication.
  `;

  return (
    <>
    <div className="bg-[#F3EDF7] overflow-x-hidden relative min-h-screen">

      <MetaTags href={location.pathname} pageName={META_TAGS.about} content={description} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

      <Header />
      <Element name="About">
      <div className='flex items-center gap-[15px] flex-col pb-[64px] px-[64px] max-sm:px-[24px] z-[1 relative]'>
        <h1 className="heading1 text-[var(--neutral-700)] max-[1100px]:text-center">
          <span className='text-[var(--primary-700)]'>About us</span>
        </h1>
        <p className='text-[var(--neutral-700)] max-w-[1090px] text-center'>
          Your go-to destination for seamless and comprehensive language translation services. At Byte translate, we're dedicated to breaking down language barriers and fostering global communication.      </p>
      </div>
    </Element>
     
    </div>
    </>
  );
};

export default About;