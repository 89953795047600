import { Navigate, useRouteError } from "react-router-dom";

function Error() {
  const error = useRouteError();
  console.log(error);

  return <Navigate to="/" replace />;
}

export default Error;
