import { useLocation } from "react-router-dom";
import { useAddKeyMutation, useDeleteKeyMutation, useGetKeysQuery, useGetProjectByIdQuery, useUpdateKeyMutation, useUploadTranslationMutation } from "../../redux/storeApis";
import { useSnackBarManager } from "./useSnackBarManager";
import { useEffect, useState } from "react";
import { SNACK_BAR_MSG } from "../../constants/Index";

export function useFilePageManager() {
    const location = useLocation();
    const file = location?.state?.file;
    const fileId = file?.id;
    const projectId = file?.project_id;
    const fileName = file?.name;
    const projectName = location?.state?.projectName;

    const userRole = location?.state?.userRole;

    const [addKey, { isLoading: isLoadingAddKey }] = useAddKeyMutation();
    const [deleteKey, { isLoading: isLoadingDeleteKey }] = useDeleteKeyMutation();
    const [updateKey, { isLoading: isLoadingUpdateKey }] = useUpdateKeyMutation();
    const [uploadTranslation] = useUploadTranslationMutation();
    const { data: projectDetails, isLoading: isLoadingProjectDetails } = useGetProjectByIdQuery(projectId);
    const { data: keys, isLoading: isLoadingKeys, isSuccess: isSuccessKeys, refetch: refetchKeys } = useGetKeysQuery(projectId);

    const { fnShowSnackBar } = useSnackBarManager();

    const [addKeyModal, setAddKeyModal] = useState(false);
    const [delKeyModal, setDelKeyModal] = useState({ status: false, id: null });
    const [updateKeyModal, setUpdateKeyModal] = useState({ status: false, id: null });
    const [translationModal, setTranslationModal] = useState(false);
    const [inputKey, setInputKey] = useState('');
    const [selectedProjectLanguageId, setSelectedProjectLanguageId] = useState(null);
    const [search, setSearch] = useState('');
    const [filterKeys, setFilterKeys] = useState([]);

    const fileKeys = keys?.filter((key) => key?.file_id == fileId);
    const projectLanguages = projectDetails?.[0]?.languages;
    const isExistProjectLanguages = projectLanguages?.length > 0;

    const isLoading = isLoadingProjectDetails || isLoadingKeys;

    useEffect(() => { setFilterKeys(fileKeys) }, [isSuccessKeys, keys]);
    useEffect(() => { fnFilterKeysData() }, [search]);

    const fnValidateInputKey = (key) => {
        const regex = /^[a-zA-Z0-9_]+\.[a-zA-Z0-9_]+$/;
        return regex?.test(key);
    };

    const fnAddKey = () => {
        // const isValidInputKey = fnValidateInputKey(inputKey);
        // if (isValidInputKey) {
        const body = { key: inputKey, file_id: fileId, project_id: projectId };
        addKey(body).unwrap().then((payload) => {
            if (payload.success) {
                setAddKeyModal(false);
                setInputKey('');
                fnShowSnackBar('Key added successfully!');
                setSearch('')
            }
        }
        ).catch((error) => {
            setAddKeyModal(false);
            setInputKey('');
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });
        // } 
        // else {
        //     fnShowSnackBar('Please enter a valid key!');
        // }

    };

    const fnDeleteKey = () => {
        deleteKey(delKeyModal?.id).unwrap().then((payload) => {
            if (payload.success) {
                setDelKeyModal({ status: false, id: null });
                fnShowSnackBar('key deleted successfully!');
                setSearch('')
            }
        }
        ).catch((error) => {
            console.log(error);
            setDelKeyModal({ status: false, id: null });
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });
    };

    const fnUpdateKey = () => {
        const body = { id: updateKeyModal?.id, key: inputKey }
        updateKey(body).unwrap().then((payload) => {
            if (payload.success) {
                setUpdateKeyModal({ status: false, id: null });
                setInputKey('');
                fnShowSnackBar('Key updated successfully!');
                setSearch('')
            }
        }
        ).catch((error) => {
            console.log(error);
            setInputKey('');
            setUpdateKeyModal({ status: false, id: null });
            fnShowSnackBar(SNACK_BAR_MSG.ERROR);
        });

    };

    const fnInputChange = async (event) => {
        if (selectedProjectLanguageId) {
            try {

                const files = event.target.files;
                const formData = new FormData();

                // Append each selected file to FormData
                for (let i = 0; i < files?.length; i++) {
                    formData.append('file', files[0]);
                }

                formData.append("file_id", fileId);
                formData.append("language_id", selectedProjectLanguageId);

                await uploadTranslation(formData).unwrap().then((payload) => {
                    if (payload.success) {
                        setTranslationModal(false);
                        fnShowSnackBar("Files uploaded successfully!");
                    }
                }
                ).catch(() => {
                    setTranslationModal(false);
                    fnShowSnackBar(SNACK_BAR_MSG.ERROR)
                });

            } catch (error) {
                setTranslationModal(false);
                fnShowSnackBar("There was an error uploading the file(s), please try again!");
            }
        } else if (!isExistProjectLanguages) {
            setTranslationModal(false);
            fnShowSnackBar('Please first add your language in project!');
        } else {
            fnShowSnackBar('Please select language first!');
            console.log('No Translations')
        }
    };

    const fnClickImportBtn = () => {
        if (!isExistProjectLanguages) { fnShowSnackBar('Go back, add language in your Project First!') }
        else { setTranslationModal(true); }
    };

    const fnInputSearch = (event) => {
        const searchValue = event.target.value;
        if (searchValue) { setSearch(searchValue) }
        else { setSearch('') }
    };

    const fnFilterKeysData = () => {
        const filteredData = fileKeys?.filter((item) => item?.key?.toLowerCase()?.includes(search?.toLowerCase()))
        setFilterKeys(filteredData)
    };

    return {

        // params
        file,
        fileId,
        projectId,
        fileName,
        projectName,
        userRole,

        // loading apis
        isLoading,
        isLoadingAddKey,
        isLoadingDeleteKey,
        isLoadingUpdateKey,

        // apis data
        projectDetails,
        keys,

        // variables
        fileKeys,
        projectLanguages,
        isExistProjectLanguages,

        // all useStates 
        addKeyModal,
        delKeyModal,
        updateKeyModal,
        translationModal,
        inputKey,
        selectedProjectLanguageId,
        search,
        filterKeys,

        setAddKeyModal,
        setDelKeyModal,
        setUpdateKeyModal,
        setTranslationModal,
        setInputKey,
        setSelectedProjectLanguageId,
        setSearch,
        setFilterKeys,

        // all functions
        fnAddKey,
        fnDeleteKey,
        fnUpdateKey,
        fnInputChange,
        fnClickImportBtn,
        fnInputSearch,
        fnFilterKeysData,

    }
}