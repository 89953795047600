import React from "react";

import "./RouteConstants";
import { Outlet, createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./RouteConstants";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/Signup.jsx";
import ForgotPassword from "../pages/forgetPassword/ForgetPassword.jsx";
import AppLayout from "./AppLayout.jsx";
import Error from "../components/ui/error/Error.jsx";
import ProtectedRoutes from "./ProtectedRoutes.jsx";
import Home from "../pages/home/Home.jsx";
import LanguagePage from "../pages/languagePage/LanguagePage.jsx";
import FilePage from "../pages/filePage/FilePage.jsx";
import AllFilesPage from "../pages/allFilesPage/AllFilesPage.jsx";
import ProjectDetails from "../pages/allLanguagesPage/ProjectDetails.jsx";
import InvitationResponse from "../pages/landingPages/InvitationResponse.jsx";
import ContributorPage from "../pages/contributorPage/ContributorPage.jsx";
import InvitationContributePage from "../pages/invitationPage/InvitationContributePage.jsx";
import VerificationCode from "../pages/verificationCode/VerificationCode.jsx";
import DashBoardPage from "../pages/dashBoardPage/DashBoardPage.jsx";
import ConditionalRoutes from "./ConditionalRoutes.jsx";
import ProfilePage from "../pages/profilePage/ProfilePage.jsx";
import About from "../pages/about/About.jsx";
import Feature from "../pages/feature/Feature.jsx";
import Contact from "../pages/contact/Contact.jsx";
import SettingsPage from "../pages/settingsPage/SettingsPage.jsx";

const ReactRoute = createBrowserRouter([

  {
    path: `${ROUTES.login}`,
    element: <Login />,
  },
  {
    element : <ConditionalRoutes/>,
    children: [
      {
        path: `${ROUTES.home}`,
        element: <Home />
      },
      {
        path: `${ROUTES.about}`,
        element: <About />,
      },
      {
        path: `${ROUTES.features}`,
        element: <Feature />,
      },
      {
        path: `${ROUTES.contact}`,
        element: <Contact />,
      },
    ]
  },
  {
    path: `${ROUTES.signup}`,
    element: <Signup />,
  },
  {
    path: `${ROUTES.confirmCode}`,
    element: <VerificationCode />,
  },
  {
    path: `${ROUTES.invitation}`,
    element: <InvitationResponse />,
  },
  {
    path: `${ROUTES.forgetPassword}`,
    element: <ForgotPassword />,
  },
  {
    path: `${ROUTES.invitationContribute}`,
    element: <InvitationContributePage />,
  },
  {
    element: <AppLayout />,
    errorElement: <Error />,

    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: `${ROUTES.dashboard}`,
            element: <DashBoardPage />
          },
          {
            path: `${ROUTES.language}`,
            element: <LanguagePage />
          },
          {
            path: `${ROUTES.file}`,
            element: <FilePage />
          },
          {
            path: `${ROUTES.projectDetails}`,
            element: <ProjectDetails />
          },
          {
            path: `${ROUTES.contributor}`,
            element: <ContributorPage />
          },
          {
            path: `${ROUTES.profile}`,
            element: <ProfilePage />
          },
          {
            path: `${ROUTES.settings}`,
            element: <SettingsPage />
          },
        ],
      },
    ],
  },
]);

export default ReactRoute;
