import React, { useEffect, useState } from 'react';
import { useAddFileFtpsMutation, useAddLanguageMutation, useDeleteLanguageMutation, useGetLanguagesQuery, useGetProjectByIdQuery, useGetProjectFilesQuery, useGetProjectFtpQuery, useGetTranslationProgressQuery, useGetUserProjectQuery, useUpdateLanguageMutation, } from '../../redux/storeApis';
import { useLocation } from 'react-router-dom';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ROUTES } from '../../reactRoute/RouteConstants';
import ViewUiBox from '../../components/views/ViewUiBox';
import AllFilesPage from '../allFilesPage/AllFilesPage';
import { AddIcon, DeleteIcon, EditIcon, RightArrowIcon, SettingIcon } from '../../icons/Icons';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { KEYS, META_TAGS, PROJECT_TYPE, ROLES, SNACK_BAR_MSG } from '../../constants/Index';
import { CircleLoader, EmptyLoader, Loading } from '../../components/loader/Loader';
import DZModal from '../../components/modal/DZModal';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { setLocalStorage } from '../../components/localStorage';
import PercentageBar from '../../components/percentageBar/PercentageBar';
import { calculatePercentage } from '../../lib/functions';
import PP2Service from '../../services';
import { useGetUserRole } from '../../lib/customHooks/useGetUserRole';
import MetaTags from '../../components/seo/MetaTags';
import { useNavigationManager } from '../../lib/customHooks/useNavigationManager';

const ProjectDetails = () => {
    const allFormatOptions = [
        { formatName: "JSON (.json)", value: '.json' },
        { formatName: "PHP Array (.php)", value: '.php' },

    ];
    const location = useLocation();
    const selectedProjectId = location?.state?.projectId;
    const selectedProjectType = location?.state?.type;
    const invitationProjectId = location?.state?.invitationProjectId;

    const selectedProjectUserRole = location?.state?.userProjectRole;

    const { isUserOwner, isUserAdmin, isUserTranslator } = useGetUserRole(selectedProjectUserRole);
    const { fnNavigateToLanguage, fnGoBack } = useNavigationManager();

    const isProjectPersonal = selectedProjectType == PROJECT_TYPE.personal;
    const isProjectInvited = selectedProjectType == PROJECT_TYPE.invited;

    const { data: allLanguages, isLoading: isLoadingAllLanguages } = useGetLanguagesQuery();
    const { data: translationProgress, refetch: refetchTranslationProgress } = useGetTranslationProgressQuery(isProjectPersonal ? selectedProjectId : invitationProjectId);
    const { data: projectDetails, isLoading: isLoadingProjectDetails } = useGetProjectByIdQuery(selectedProjectId);
    const { data: invitedProjectDetails, isLoading: isLoadingInvitedProjectDetails } = useGetUserProjectQuery(invitationProjectId);
    const { data: allFiles, isLoading: isLoadingAllFiles } = useGetProjectFilesQuery(isProjectPersonal ? selectedProjectId : invitationProjectId);
    const [addLanguage, { isLoading: isLoadingAddLang }] = useAddLanguageMutation();
    const [updateLanguage, { isLoading: isLoadingUpdateLang }] = useUpdateLanguageMutation();
    const [deleteLanguage, { isLoading: isLoadingDeleteLang }] = useDeleteLanguageMutation();

    const [addFileFtps, { isLoading: isLoadingAddFileFtps }] = useAddFileFtpsMutation();
    const [updateFileFtps, { isLoading: isLoadingUpdateFileFtps }] = useAddFileFtpsMutation();
    const { data: getAllProjectFtps } = useGetProjectFtpQuery(isProjectPersonal ? selectedProjectId : invitationProjectId);

    const allProjectFtps = getAllProjectFtps?.data?.project_ftps;
    const { fnShowSnackBar } = useSnackBarManager();

    const [languageId, setLanguageId] = useState(null);
    const [langModal, setLangModal] = useState(false);
    const [updateLangModal, setUpdateLangModal] = useState(false);
    const [deleteLangModal, setDeleteLangModal] = useState({ status: false, id: null });
    const [selectedProjectLangId, setSelectedProjectLangId] = useState(null);
    const [isExecuting, setIsExecuting] = useState(false);
    const [settingModal, setSettingModal] = useState(false);
    const [fileFtpsData, setFileFtpsData] = useState({ file_id: null, file_name: '', path: '', project_ftp_id: null, language_id: null, default_export : null });

    const [loader, setLoader] = useState({ state: false, id: null });
    const [fileFtpsBtnTxt, setFileFtpsBtnTxt] = useState('Save');
    const [fileFtpFetchDataLoader, setFileFtpFetchDataLoader] = useState(false);

    const isUpdateBtn = fileFtpsBtnTxt == 'Update';

    console.log('invitedProjectDetails', invitedProjectDetails);

    const projectInfo = isProjectInvited ? invitedProjectDetails : projectDetails;

    const projectLanguages = isProjectInvited ? projectInfo?.user_projects : projectInfo?.[0]?.languages;
    const projectName = isProjectInvited ? projectInfo?.name : projectInfo?.[0]?.name;

    const isExistProjectLanguages = projectLanguages?.length > 0 || projectInfo?.language;

    const isLoading = isLoadingProjectDetails || isLoadingInvitedProjectDetails;

    useEffect(() => { setLocalStorage(KEYS.selected_project_id, selectedProjectId) }, [selectedProjectId]);

    useEffect(() => { refetchTranslationProgress() }, []);

    useEffect(() => { setFileFtpsData({ ...fileFtpsData, file_id: allFiles?.[0]?.id }) }, [allFiles]);


    const fnAddLanguage = () => {
        if (isExecuting) return; // Prevent multiple executions
        setIsExecuting(true);
        const checkAlreadyExistLang = projectLanguages?.find((lang) => lang?.language_id == languageId);
        if (checkAlreadyExistLang) {
            console.log('already exist');
            fnShowSnackBar('This language is already exist!')
            setLangModal(false);
            setLanguageId(null);
            setIsExecuting(false);
            return;
        }
        else {
            const body = { project_id: selectedProjectId, language_id: languageId };
            addLanguage(body).unwrap().then((payload) => {
                if (payload.success) {
                    setLangModal(false);
                    fnShowSnackBar('Language added successfully!');
                    setLanguageId(null);
                }
            }
            ).catch((error) => {
                setLangModal(false);
                setLanguageId(null);
                fnShowSnackBar(SNACK_BAR_MSG.ERROR);
            }).finally(() => {
                setIsExecuting(false);
            });
        }
    };

    const fnDeleteLanguage = () => {
        deleteLanguage(deleteLangModal?.id).unwrap().then((payload) => {
            if (payload.success) {
                setDeleteLangModal({ id: null, status: false });
                fnShowSnackBar('Language deleted successfully!');
            }
        }
        ).catch((error) => {
            fnShowSnackBar(SNACK_BAR_MSG.ERROR);
        });
    };

    const fnUpdateLanguage = () => {

        const body = { project_id: selectedProjectId, language_id: selectedProjectLangId, new_language_id: languageId };
        updateLanguage(body).unwrap().then((payload) => {
            if (payload.success) {
                setUpdateLangModal(false);
                setLanguageId(null)
                fnShowSnackBar('Language updated successfully!')
            }
        }
        ).catch((error) => {
            setUpdateLangModal(false);
            setLanguageId(null);
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });

    };

    const fnAddFileFtps = () => {
        const checkValidation = fileFtpsData?.file_id && fileFtpsData?.file_name && fileFtpsData?.path && fileFtpsData?.project_ftp_id && fileFtpsData?.language_id && fileFtpsData?.default_export;
        if (checkValidation) {
            addFileFtps(fileFtpsData)?.unwrap()?.then((payload) => {
                if (payload?.success) {
                    setSettingModal(false);
                    fnShowSnackBar('Save Successfully!');
                }
            }
            ).catch((error) => {
                fnShowSnackBar(SNACK_BAR_MSG.ERROR);
            })
        } else {
            fnShowSnackBar('Please fill all fields!')
        }
    };

    const fnUpdateFileFtps = () => {
        const checkValidation = fileFtpsData?.file_id && fileFtpsData?.file_name && fileFtpsData?.path && fileFtpsData?.project_ftp_id && fileFtpsData?.language_id && fileFtpsData?.default_export;
        const body = {
            file_id: fileFtpsData?.file_id,
            file_name: fileFtpsData?.file_name,
            path: fileFtpsData?.path,
            project_ftp_id: fileFtpsData?.project_ftp_id,
            default_export: fileFtpsData?.default_export,
            language_id: fileFtpsData?.language_id
        };

        if (checkValidation) {
            updateFileFtps(body)?.unwrap()?.then((payload) => {
                if (payload?.success) {
                    setSettingModal(false);
                    fnShowSnackBar('Save Successfully!');
                }
            }
            ).catch((error) => {
                fnShowSnackBar(SNACK_BAR_MSG.ERROR);
            })
        } else {
            fnShowSnackBar('Please fill all fields!')
        }
    };

    const fnOnClickSettingIcon = async (languageId) => {
        try {
            setLoader({ state: true, id: languageId });

            const response = await PP2Service.fetch({ url: `file-ftps/${languageId}?file_id=${fileFtpsData?.file_id}` });

            if (response?.success) {
                const fileFtpData = response?.data?.file_ftp;
                if (fileFtpData) { setFileFtpsData(fileFtpData); };
                setFileFtpsBtnTxt('Update');
                setLoader(false);
                setSettingModal(true);

            } else {
                setFileFtpsData({ file_id: allFiles?.[0]?.id });
                setFileFtpsBtnTxt('Save');
                setLoader(false);
                setSettingModal(true);
            }

            setFileFtpsData((pre) => ({ ...pre, language_id: languageId }));


        } catch (error) {
            setLoader(false);
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        }
    };

    const fnGetDefaultFieldsFtpFile = async (file_id) => {
        setFileFtpFetchDataLoader(true);
        const response = await PP2Service.fetch({ url: `file-ftps/${fileFtpsData?.language_id}?file_id=${file_id}` });

        if (response?.success) {
            const fileFtpData = response?.data?.file_ftp;
            if (fileFtpData) { setFileFtpsData(fileFtpData); };
            setFileFtpsBtnTxt('Update');
            setFileFtpFetchDataLoader(false);
        } else {
            setFileFtpsBtnTxt('Save');
            setFileFtpFetchDataLoader(false);
            setFileFtpsData({ file_id, file_name: '', path: '', default_export: '', project_ftp_id: null, language_id: fileFtpsData?.language_id });
        }
    };

    return (
        <div className='flex items-center flex-col justify-center my-[6vw] w-full'>

            <MetaTags href={location.pathname} pageName={`Byte Translate | ${projectName ?? 'Project Details'}`} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

            <div className='flex flex-col items-center gap-[24px] max-[1100px]:px-[6vw] w-full px-[16vw]'>

                <div className="flex items-center justify-between w-full">

                    <div onClick={() => fnGoBack()} className="flex items-center gap-[20px] w-fit max-w-[1200px] cursor-pointer group">

                        <RightArrowIcon className={'cursor-pointer w-[32px] h-[32px] group-hover:translate-x-[-10px] transition'} iconColor={'var(--Primary)'} />

                        <span className='text-[var(--Primary)] text-[24px] w-fit'>
                            {projectName}
                        </span>

                    </div>

                </div>

                <ViewUiBox className={'w-full'} showHeader={false} showAddIcon={false} onClickAddBtn={() => setLangModal(true)} topSpacing={true}>

                    <div className='add__bar'>

                        <h1 className='project__name'>
                            {
                                isLoading ? 'Loading...'
                                    : isExistProjectLanguages ? 'All Languages' : 'No Languages'
                            }
                        </h1>

                        {isUserOwner && <div onClick={() => { setLangModal(true); }} className='add__btn'>
                            <AddIcon />
                        </div>}

                    </div>

                    {
                        isLoading ? <Loading /> :
                            <>
                                {isProjectPersonal ?
                                    (isExistProjectLanguages && projectLanguages?.map((language) => {

                                        const languageId = language?.lang?.id;
                                        const userRole = ROLES.owner;

                                        return (
                                            <div className="project__div">

                                                <h3 onClick={() => fnNavigateToLanguage({ language, projectName, userRole, type: PROJECT_TYPE.personal })} className="project__heading">
                                                    {language?.lang?.name}
                                                </h3>

                                                <div className={'w-3/6'}>
                                                    <PercentageBar percentage={calculatePercentage(translationProgress?.translation_count?.[languageId], translationProgress?.total_keys, 0)} />
                                                </div>

                                                <div className="flex items-center gap-[24px]">

                                                    <div className="add__btn" onClick={() => fnOnClickSettingIcon(languageId)}>
                                                        {loader?.id == languageId ? <CircleLoader /> : <SettingIcon />}
                                                    </div>

                                                    <div className="add__btn" onClick={() => { setSelectedProjectLangId(language?.language_id); setUpdateLangModal(true) }}>
                                                        <EditIcon />
                                                    </div>

                                                    <div className="add__btn" onClick={() => setDeleteLangModal({ id: language?.id, status: true })}>
                                                        <DeleteIcon />
                                                    </div>

                                                </div>

                                            </div>
                                        )

                                    })
                                    )
                                    : projectLanguages?.map((lang) => {

                                        const userRole = lang?.role;
                                        const languageId = lang?.language_id;

                                        return (
                                            <div className='project__div'>

                                                <h3 onClick={() => fnNavigateToLanguage({ language: lang?.language, project: lang, projectName, userRole, type: PROJECT_TYPE.invited })} className="project__heading">
                                                    {lang?.language?.name}
                                                </h3>

                                                <div className={'w-3/6'}>
                                                    <PercentageBar percentage={calculatePercentage(translationProgress?.translation_count?.[lang?.language?.id], translationProgress?.total_keys, 0)} />
                                                </div>

                                                <div className="add__btn" onClick={() => fnOnClickSettingIcon(languageId)}>
                                                    {loader?.id == languageId ? <CircleLoader /> : <SettingIcon />}
                                                </div>

                                                {isUserOwner && <div className="flex items-center gap-[24px]">

                                                    <div className="add__btn" onClick={() => { setSelectedProjectLangId(projectInfo?.language?.id); setUpdateLangModal(true) }}>
                                                        <EditIcon />
                                                    </div>

                                                    <div className="add__btn" onClick={() => setDeleteLangModal({ id: projectInfo?.language?.id, status: true })}>
                                                        <DeleteIcon />
                                                    </div>

                                                </div>}

                                            </div>
                                        )

                                    })
                                }
                                {!isUserTranslator &&
                                    (isExistProjectLanguages ? <AllFilesPage allFiles={allFiles} projectName={projectName} userRole={selectedProjectUserRole} isExistProjectLanguages={isExistProjectLanguages} /> : <EmptyLoader />)
                                }
                            </>
                    }


                    <DZModal open={langModal} onClose={() => setLangModal(false)} >
                        <h1 style={{ marginBottom: 20 }}>{'Select Language Which You Want'}</h1>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel style={{ minWidth: 100 }}>Language</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={languageId}
                                    label="Language"
                                    onChange={(event) => setLanguageId(event.target.value)}
                                >
                                    {allLanguages?.map((lang) => <MenuItem value={lang?.id}>{lang?.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        {languageId && <DZBtn
                            disabled={isLoadingAddLang}
                            onClick={() => fnAddLanguage()}
                            btnStyle={{ marginTop: '10%' }} variant="contained">
                            {isLoadingAddLang ? 'Loading...' : 'Add Language'}
                        </DZBtn>}
                    </DZModal>

                    <DZModal open={updateLangModal} disabled={isLoadingUpdateLang} onKeyDown={fnUpdateLanguage} onClose={() => setUpdateLangModal(false)} >
                        <h1 style={{ marginBottom: 20 }}>{'Update Your Language'}</h1>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel style={{ minWidth: 100 }}>Language</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={languageId ?? selectedProjectLangId}
                                    label="Language"
                                    onChange={(event) => setLanguageId(event.target.value)}
                                >
                                    {allLanguages?.map((lang) => <MenuItem value={lang?.id}>{lang?.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        {languageId && <DZBtn
                            disabled={isLoadingUpdateLang}
                            onClick={() => fnUpdateLanguage()}
                            btnStyle={{ marginTop: '10%', width: 180, fontSize: 16, }} variant="contained">
                            {isLoadingUpdateLang ? 'Loading...' : 'Update Language'}
                        </DZBtn>}
                    </DZModal>

                    <DZModal open={deleteLangModal.status} disabled={isLoadingDeleteLang} onKeyDown={fnDeleteLanguage} onClose={() => setDeleteLangModal({ ...deleteLangModal, status: false })} >
                        <h1>{'Are you sure to want to delete this file ?'}</h1>
                        <DZBtn
                            disabled={isLoadingDeleteLang}
                            onClick={() => fnDeleteLanguage()}
                            btnStyle={{ marginTop: '30%', width: 180, fontSize: 16, }} variant="contained"
                        >
                            {isLoadingDeleteLang ? 'Loading...' : 'Delete Language'}
                        </DZBtn>
                    </DZModal>

                    <DZModal modalStyle={{ gap: 12, display: 'flex', flexDirection: 'column' }} open={settingModal} onClose={() => setSettingModal(false)} >

                        <select onChange={(e) => setFileFtpsData((pre) => ({ ...pre, default_export: e.target.value }))} style={{ padding : '14px 8px', background:'transparent', border : '1px solid lightgrey', borderRadius: '4px'}}>
                            <option hidden>Select Format</option>
                            {allFormatOptions?.map((format) => <option value={format?.value} selected={fileFtpsData?.default_export == format?.value} >{format?.formatName}</option>)}
                        </select>

                        <select onChange={(e) => { setFileFtpsData((pre) => ({ ...pre, file_id: e.target.value })); fnGetDefaultFieldsFtpFile(e.target.value) }} style={{ padding : '14px 8px', background:'transparent', border : '1px solid lightgrey', borderRadius: '4px'}} >
                            <option value="" hidden > Select File </option>
                            {allFiles?.map((file) => <option selected={fileFtpsData?.file_id == file?.id} value={file?.id}> {file?.name} </option>)}
                        </select>

                        <select onChange={(e) => { setFileFtpsData((pre) => ({ ...pre, project_ftp_id: e.target.value })) }} style={{ padding : '14px 8px', background:'transparent', border : '1px solid lightgrey', borderRadius: '4px'}} >
                            <option value="" hidden > Select Type </option>
                            {allProjectFtps?.map((ftp) => <option selected={fileFtpsData?.project_ftp_id == ftp?.id} value={ftp?.id} >{ftp?.deployment_name}</option>)}
                        </select>

                        <TextField
                            name='file_name'
                            value={fileFtpsData?.file_name}
                            id="outlined-basic"
                            label={!fileFtpsData?.file_name && 'File Name'}
                            variant="outlined"
                            onChange={(e) => setFileFtpsData((pre) => ({ ...pre, file_name: e.target.value }))}
                            style={{ width: '100%' }}
                        />

                        <TextField
                            name='path'
                            value={fileFtpsData?.path}
                            id="outlined-basic"
                            label={!fileFtpsData?.path && 'Path'}
                            variant="outlined"
                            onChange={(e) => setFileFtpsData((pre) => ({ ...pre, path: e.target.value }))}
                            style={{ width: '100%' }}
                        />

                        <DZBtn onClick={isUpdateBtn ? fnUpdateFileFtps : fnAddFileFtps} disabled={isLoadingAddFileFtps || isLoadingUpdateFileFtps || fileFtpFetchDataLoader} btnStyle={{ marginTop: '10%', width: '100%', fontSize: 13, }} variant="contained">
                            {fileFtpFetchDataLoader ? 'Loading...' : (isUpdateBtn ? (isLoadingUpdateFileFtps ? 'Loading...' : 'Update') : (isLoadingAddFileFtps ? 'Loading...' : 'Save'))}
                        </DZBtn>

                    </DZModal>

                </ViewUiBox>

            </div>

        </div>
    )
}

export default ProjectDetails;