import React, { useEffect, useState } from 'react'
import { useAddProjectMutation, useDeleteProjectMutation, useDeleteUserProjectMutation, useGetProjectsQuery, useUpdateProjectMutation, useUserProjectsQuery } from '../../redux/storeApis'
import { useLocation } from 'react-router-dom';
import { TextField } from '@mui/material';
import ViewLanguages from '../../components/views/ViewLanguages';
import ViewFiles from '../../components/views/ViewFiles';
import ViewUiBox from '../../components/views/ViewUiBox';
import { DeleteIcon, EditIcon, EyeIcon, SettingIcon } from '../../icons/Icons';
import './DashBoardPage.css';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { META_TAGS, PROJECT_TYPE, ROLES, SNACK_BAR_MSG } from '../../constants/Index';
import DZModal from '../../components/modal/DZModal';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { EmptyLoader, Loading } from '../../components/loader/Loader';
import MetaTags from '../../components/seo/MetaTags';
import { useNavigationManager } from '../../lib/customHooks/useNavigationManager';

const DashBoardPage = () => {

    const location = useLocation();

    const { data: allProjects, isLoading: isLoadingAllProjects, refetch: refetchProjects } = useGetProjectsQuery();
    const { data: userProjects, isLoading: isLoadingUserProjects, refetch: refetchInvitedProjects } = useUserProjectsQuery();

    const [addProject, { isLoading: isLoadingAddProject }] = useAddProjectMutation();
    const [deleteProject, { isLoading: isLoadingDeleteProject }] = useDeleteProjectMutation();
    const [updateProject, { isLoading: isLoadingUpdateProject }] = useUpdateProjectMutation();
    const [deleteUserProject] = useDeleteUserProjectMutation();

    const { fnShowSnackBar } = useSnackBarManager();
    const { fnNavigateToProjectDetails, fnNavigateToSettings } = useNavigationManager();

    const invitedProjects = userProjects?.filter((project, index, self) => index == self?.findIndex((p) => (p?.project_id === project?.project_id)));
    const isProjectAvailable = allProjects?.length > 0 || invitedProjects?.length > 0;
    const isLoading = isLoadingAllProjects || isLoadingUserProjects;

    const [projectModal, setProjectModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ status: false, projectId: null, type: null });
    const [updateModal, setUpdateModal] = useState({ status: false, projectId: null, isInvited : false });

    const [projectName, setProjectName] = useState('');

    useEffect(() => { refetchProjects(); refetchInvitedProjects(); }, []);

    const fnAddProject = () => {
        if (projectName) {
            const data = { name: projectName };
            addProject(data).unwrap().then((payload) => {
                if (payload.success) {
                    setProjectModal(false);
                    setProjectName('');
                    fnShowSnackBar('Project is Added!')
                }
            }
            ).catch((error) => {
                fnShowSnackBar(SNACK_BAR_MSG.ERROR)
            });
        } else {
            fnShowSnackBar('Please enter project name!')
        }
    };

    const fnDeleteProject = (id) => {
        if (deleteModal.type == PROJECT_TYPE.invited) {

            console.log('delete user project')

            deleteUserProject(id).unwrap().then((payload) => {
                if (payload.success) {
                    setDeleteModal({ projectId: null, status: false });
                    fnShowSnackBar('Project deleted successfully!')
                }
            }
            ).catch((error) => {
                fnShowSnackBar(SNACK_BAR_MSG.ERROR)
            });

        } else {

            console.log('delete project')


            deleteProject(id).unwrap().then((payload) => {
                if (payload.success) {
                    setDeleteModal({ projectId: null, status: false });
                    fnShowSnackBar('Project deleted successfully!')
                }
            }
            ).catch((error) => {
                fnShowSnackBar(SNACK_BAR_MSG.ERROR)
            });

        }
    };

    const fnUpdateProject = (id) => {
        const body = { id: id, name: projectName };
        updateProject(body).unwrap().then((payload) => {
            if (payload.success) {
                setUpdateModal({ status: false, projectId: null, isInvited : false });
                fnShowSnackBar('Project name updated successfully!');
                setProjectName('');
            }
        }
        ).catch((error) => {
            setUpdateModal({ status: false, projectId: null, isInvited : false });
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });

    };


    return (
        <>
            <MetaTags href={location.pathname} pageName={META_TAGS.dashboard} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

            <ViewUiBox projectName={'All Projects'} onClickAddBtn={() => setProjectModal(true)}>

                {isLoading ? <Loading />
                    : isProjectAvailable ?
                        <>
                            {/* Personal Projects */}
                            <div className="projects__div">

                                {allProjects?.map((project) => {

                                    const languages = project?.languages;
                                    const files = project?.files;
                                    const projectName = project?.name;
                                    const userProjectRole = ROLES.owner;

                                    return (
                                        <>

                                            <div className="project__div">

                                                <h3 onClick={() => fnNavigateToProjectDetails({ project, userProjectRole, type: PROJECT_TYPE.personal })} style={{ cursor: 'pointer' }} className="project__heading">
                                                    {projectName}
                                                </h3>

                                                {/* project languages */}
                                                {/* <div className="languages__div">
                                                    {languages?.map((language) => <ViewLanguages language={language} projectName={projectName} type={PROJECT_TYPE.personal} userRole={ROLES.owner} />)}
                                                </div> */}

                                                {/* project files */}
                                                {/* <div className="languages__div">
                                                    {files?.map((file) => <ViewFiles file={file} projectName={projectName} />)}
                                                </div> */}

                                                <div className="flex items-center gap-[24px] max-lg:gap-[12px]">

                                                    <div className="add__btn" onClick={() => fnNavigateToSettings({ projectId: project?.id })} >
                                                        <SettingIcon />
                                                    </div>

                                                    <div className="add__btn" onClick={() => { setUpdateModal({ status: true, projectId: project?.id, isInvited : false }); setProjectName(projectName) }}>
                                                        <EditIcon />
                                                    </div>

                                                    <div className="add__btn" onClick={() => setDeleteModal({ status: true, projectId: project?.id, type: PROJECT_TYPE.personal })} >
                                                        <DeleteIcon />
                                                    </div>

                                                </div>

                                            </div >

                                        </>
                                    )
                                })}

                            </div>

                            {/* invited Projects */}
                            <div className="projects__div">

                                {invitedProjects?.map((project) => {

                                    const isExistProject = project?.project;
                                    const projectId = project?.project_id;
                                    const projectName = isExistProject?.name;
                                    const userProjectRole = project?.role;
                                    const projectLanguages = isExistProject?.languages;
                                    const projectFiles = isExistProject?.files;

                                    const userRole = project?.role;
                                    const isUserAdmin = userRole == ROLES.admin;


                                    return (
                                        <>
                                            {isExistProject && <div className="project__div" key={projectId}>

                                                <h3 onClick={() => fnNavigateToProjectDetails({ project, userProjectRole, type: PROJECT_TYPE.invited })} style={{ cursor: 'pointer' }} className="project__heading">
                                                    {projectName}
                                                    <span style={{ color: 'orange', paddingLeft: 10, fontSize: 11 }}>{'SHARED'}</span>
                                                </h3>

                                                {/* invited project languages */}
                                                {/* <div className="languages__div">
                                                    {projectLanguages?.map((project) => (
                                                        <ViewLanguages language={project?.language} project={project} projectName={projectName} type={PROJECT_TYPE.invited} userRole={userRole} />
                                                    ))}
                                                </div> */}

                                                {/* invited project files */}
                                                {/* <div className="languages__div">
                                                    {projectFiles?.map((file) => {
                                                        return (
                                                            <ViewFiles projectName={projectName} file={file} userRole={userRole} />
                                                        )
                                                    })}
                                                </div> */}

                                                <div className="flex items-center gap-[24px] max-lg:gap-[12px]">

                                                    {isUserAdmin && <div className="add__btn" onClick={() => fnNavigateToSettings({ projectId })} >
                                                        <SettingIcon />
                                                    </div>}

                                                    <div className="add__btn" onClick={() => { setUpdateModal({ status: true, projectId, isInvited : true }); setProjectName(projectName) }}>
                                                        <EyeIcon />
                                                    </div>

                                                    <div className="add__btn" onClick={() => setDeleteModal({ status: true, projectId, type: PROJECT_TYPE.invited })}>
                                                        <DeleteIcon />
                                                    </div>

                                                </div>

                                            </div>
                                            }
                                        </>
                                    );
                                })}

                            </div>
                        </>
                        : <EmptyLoader />
                }

            </ViewUiBox >


            {/* All Modals */}

            <DZModal open={projectModal} disabled={isLoadingAddProject} onKeyDown={fnAddProject} onClose={() => setProjectModal(false)} >
                <TextField id="outlined-basic" value={projectName} onChange={(event) => setProjectName(event.target.value)} label="Project Name" variant="outlined" style={{ width: '100%' }} />
                <DZBtn disabled={isLoadingAddProject} onClick={() => fnAddProject()} variant="contained">{isLoadingAddProject ? 'Loading...' : 'Add Project'}</DZBtn>
            </DZModal>

            <DZModal open={deleteModal.status} disabled={isLoadingDeleteProject} onKeyDown={() => fnDeleteProject(deleteModal?.projectId)} onClose={() => setDeleteModal({ ...deleteModal, status: false })} >
                <h1>{'Are you sure to want to delete this project ?'}</h1>
                <DZBtn disabled={isLoadingDeleteProject} onClick={() => { if (deleteModal?.projectId) { fnDeleteProject(deleteModal?.projectId) } }} btnStyle={{ marginTop: '20%', backgroundColor: 'red' }} variant="contained">
                    {isLoadingDeleteProject ? 'Loading...' : 'Delete Project'}
                </DZBtn>
            </DZModal>

            <DZModal open={updateModal.status} onKeyDown={fnUpdateProject} onClose={() => { setUpdateModal({ status: false, projectId: null, isInvited : false }); setProjectName('') }} >
                <TextField id="outlined-basic" value={projectName} onChange={(event) => setProjectName(event.target.value)} label="Project Name" variant="outlined" style={{ width: '100%', marginBottom : updateModal.isInvited && '10%' }} />
                { !updateModal.isInvited && <DZBtn disabled={isLoadingUpdateProject} onClick={() => { if (updateModal?.projectId) { fnUpdateProject(updateModal?.projectId) } }} btnStyle={{ marginTop: '20%', width: 180, fontSize: 13, }} variant="contained">
                    {isLoadingUpdateProject ? 'Loading...' : 'Change Project Name'}
                </DZBtn>}
            </DZModal>

        </>
    )
}

export default DashBoardPage