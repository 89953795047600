import { ROLES } from "../../constants/Index";

export function useGetUserRole (role) {
    
    const isUserOwner = role == ROLES.owner;
    const isUserTranslator = role == ROLES.translator;
    const isUserAdmin = role == ROLES.admin;

    return {

        isUserOwner,
        isUserTranslator,
        isUserAdmin,

    }
}