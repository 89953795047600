import { useLocation } from "react-router-dom";
import { useSnackBarManager } from "./useSnackBarManager";
import { useAddTranslationMutation, useDownloadTranslationMutation, useFileDeployToServerMutation, useGetFileFtpDetailQuery, useGetKeysQuery, useGetProjectByIdQuery, 
    useGetProjectFilesQuery, useGetProjectFtpQuery, useGetTranslationsQuery, useUpdateTranslationMutation } from "../../redux/storeApis";
import { useEffect, useState } from "react";
import { PROJECT_TYPE } from "../../constants/Index";

export function useLanguagePageManager() {
    const location = useLocation();
    const { fnShowSnackBar } = useSnackBarManager();
    const language = location?.state?.language;
    const projectType = location?.state?.type;
    const project = location?.state?.project;

    const userRole = location?.state?.userRole;

    const isProjectPersonal = projectType == PROJECT_TYPE.personal;
    const isProjectInvited = projectType == PROJECT_TYPE.invited;

    const projectId = isProjectInvited ? project?.project_id : language?.project_id;

    const languageId = isProjectInvited ? language?.id : language?.language_id;
    const languageName = isProjectInvited ? language?.name : language?.lang?.name;
    const projectName = location?.state?.projectName;

    const { data: translations, isLoading : isLoadingTranslations } = useGetTranslationsQuery(projectId);
    const { data: keys, isLoading : isLoadingKeys } = useGetKeysQuery(projectId);
    const { data: allFiles, isLoading : isLoadingAllFiles, isSuccess: isSuccessFiles } = useGetProjectFilesQuery(projectId);
    const { data : getAllProjectFtps } = useGetProjectFtpQuery(projectId);

    const [addTranslation, { isLoading: isLoadingAddTranslation }] = useAddTranslationMutation();
    const [updateTranslation, { isLoading: isLoadingUpdateTranslation }] = useUpdateTranslationMutation();
    const [downloadTranslation, { isLoading: isLoadingDownloadTranslation }] = useDownloadTranslationMutation();
    const [fileDeployToServer, { isLoading : isLoadingFileDeploy } ] = useFileDeployToServerMutation();
    const { data: projectDetails, isLoading : isLoadingProjectDetails } = useGetProjectByIdQuery(projectId);

    const isLoading = isLoadingTranslations || isLoadingKeys || isLoadingAllFiles || isLoadingProjectDetails;
    const filesDetails = isSuccessFiles ? (allFiles?.map((item) => ({ name: item?.name, id: item?.id }))) : [];
    const allFilesDropDown = [{ name: 'All', id: 'all' }, ...filesDetails];
    const projectLanguages = projectDetails?.[0]?.languages;
    const allProjectFtps = getAllProjectFtps?.data?.project_ftps;

    const [translationsForLanguage, setTranslationsForLanguage] = useState([]);
    const [filteredKeys, setFilteredKeys] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFile, setSelectedFile] = useState('all');
    const [downloadModal, setDownloadModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [downloadFileId, setDownloadFileId] = useState(null);
    const [exportFileId, setExportFileId] = useState(null);
    const [selectedServerId, setSelectedServerId] = useState(null);
    const [fileName, setFileName] = useState('');
    const [format, setFormat] = useState('json');
    const [selectedLangId, setSelectedLangId] = useState(languageId);

    const { data: fileFtpDetail, isLoading: isLoadingFileFtpDetail, isFetching: isFetchingFileFtpDetail } = useGetFileFtpDetailQuery({ language_id: selectedLangId, file_id: exportFileId });

    // Filter translations based on selected language
    useEffect(() => {
        const filteredTranslations = translations?.filter(translation => translation?.language_id == selectedLangId);
        setTranslationsForLanguage(filteredTranslations);
    }, [selectedLangId, translations]);

    useEffect(() => { setFilteredKeys(keys); }, [keys]);

    useEffect(() => { filterKeysByTranslation(); }, [searchTerm, translationsForLanguage]);

    useEffect(() => { fnGetFileKeys() }, [selectedFile]);

    const fnUpdateTranslation = async (projectId, keyId, word) => {
        const body = { key_id: keyId, language_id: selectedLangId, project_id: projectId, text: word };

        try {
            const payload = await updateTranslation(body).unwrap();
            if (payload.success) { console.log('updated'); }
        }
        catch (error) { console.error('error', error); fnShowSnackBar('Translation not saved due to some error'); }
    };

    const fnAddTranslation = async (projectId, keyId, word) => {
        const body = { project_id: projectId, key_id: keyId, language_id: selectedLangId, text: word };

        try {
            const payload = await addTranslation(body).unwrap();
            if (payload.success) { console.log('updated'); }
        }
        catch (error) { console.error('error', error); fnShowSnackBar('Translation not saved due to some error');}
    };

    const filterKeysByTranslation = () => {
        const filteredKeys = searchTerm ? keys?.filter((key) => {
            const translationForThisKey = translationsForLanguage?.find(translation => translation?.key_id == key?.id);
            const titleTranslation = translationForThisKey?.text?.toLowerCase();

            const titleKey = key?.key?.toLowerCase();
            const keyParentId = key?.parent_id;
            const titleParentKey = keys?.find((key) => key?.id == keyParentId)?.key?.toLowerCase();
            const searchTitle = searchTerm?.toLowerCase();

            return (
                selectedFile == 'all' ? titleTranslation?.includes(searchTitle) || titleKey?.includes(searchTitle) || titleParentKey?.includes(searchTitle)
                    : ((titleTranslation?.includes(searchTitle) || titleKey?.includes(searchTitle) || titleParentKey?.includes(searchTitle)) && key?.file_id == selectedFile)
            )
        }) : selectedFile == 'all' ? keys : keys?.filter((key) => (key?.file_id == selectedFile));

        setFilteredKeys(filteredKeys);
        console.log(filteredKeys);
    };

    const fnOnClickFile = (id) => {
        if (id) { setSelectedFile(id); }
        setSearchTerm('');
    };

    const fnOnSearch = (event) => {
        const inputValue = event.target.value;
        if (inputValue) { setSearchTerm(inputValue) }
        else { setSearchTerm(''); setSelectedFile((pre) => pre) }
    };

    const fnGetFileKeys = () => {
        if (selectedFile == 'all') {
            setFilteredKeys(keys);
        } else {
            const filteredKeysByFile = keys?.filter((key) => (key?.file_id == selectedFile));
            setFilteredKeys(filteredKeysByFile);
            console.log('filter key', filteredKeysByFile);
        }
    };  

    const fnDownloadTranslations = () => {
        if (downloadFileId) {
            const body = { project_id: projectId, file_id: downloadFileId, language_id: selectedLangId, format };
    
            downloadTranslation(body).unwrap().then((payload) => {
                console.log("object", payload);
    
                if (payload) {
                    let blob;
                    let selectedFileName;
    
                    switch (format) {
                        case 'php':
                            let phpContent = "<?php\n\nreturn [\n";
                            Object.keys(payload).forEach(key => {
                                phpContent += `    '${key}' => '${payload[key]}',\n`;
                            });
                            phpContent += "];\n";
                            blob = new Blob([phpContent], { type: 'text/plain' });
                            selectedFileName = `${fileName}.php`;
                            break;
    
                        case 'arb':
                            blob = new Blob([JSON.stringify(payload, null, 2)], { type: 'application/json' });
                            selectedFileName = `${fileName}.arb`;
                            break;
    
                        case 'csv':
                            let csvContent = "key,value\n";
                            Object.keys(payload).forEach(key => {
                                csvContent += `${key},${payload[key]}\n`;
                            });
                            blob = new Blob([csvContent], { type: 'text/csv' });
                            selectedFileName = `${fileName}.csv`;
                            break;
    
                        case 'properties':
                            let propertiesContent = "";
                            Object.keys(payload).forEach(key => {
                                propertiesContent += `${key}=${payload[key]}\n`;
                            });
                            blob = new Blob([propertiesContent], { type: 'text/plain' });
                            selectedFileName = `${fileName}.properties`;
                            break;
    
                        case 'resw':
                        case 'resx':
                            let resxContent = '<?xml version="1.0" encoding="utf-8"?>\n<root>\n';
                            Object.keys(payload).forEach(key => {
                                resxContent += `  <data name="${key}" xml:space="preserve">\n    <value>${payload[key]}</value>\n  </data>\n`;
                            });
                            resxContent += '</root>';
                            blob = new Blob([resxContent], { type: 'application/xml' });
                            selectedFileName = `${fileName}.${format}`;
                            break;
    
                        case 'ts':
                            let tsContent = '<?xml version="1.0" encoding="utf-8"?>\n<TS>\n';
                            Object.keys(payload).forEach(key => {
                                tsContent += `  <context>\n    <message>\n      <source>${key}</source>\n      <translation>${payload[key]}</translation>\n    </message>\n  </context>\n`;
                            });
                            tsContent += '</TS>';
                            blob = new Blob([tsContent], { type: 'application/xml' });
                            selectedFileName = `${fileName}.ts`;
                            break;
    
                        case 'strings':
                            let stringsContent = "";
                            Object.keys(payload).forEach(key => {
                                stringsContent += `"${key}" = "${payload[key]}";\n`;
                            });
                            blob = new Blob([stringsContent], { type: 'text/plain' });
                            selectedFileName = `${fileName}.strings`;
                            break;
    
                        case 'xmb':
                        case 'xtb':
                            let xmbContent = '<?xml version="1.0" encoding="UTF-8" ?>\n<!DOCTYPE messagebundle>\n<messagebundle>\n';
                            Object.keys(payload).forEach(key => {
                                xmbContent += `  <msg id="${key}">${payload[key]}</msg>\n`;
                            });
                            xmbContent += '</messagebundle>';
                            blob = new Blob([xmbContent], { type: 'application/xml' });
                            selectedFileName = `${fileName}.${format}`;
                            break;
    
                        case 'xml':
                            let xmlContent = '<?xml version="1.0" encoding="utf-8"?>\n<resources>\n';
                            Object.keys(payload).forEach(key => {
                                xmlContent += `  <string name="${key}">${payload[key]}</string>\n`;
                            });
                            xmlContent += '</resources>';
                            blob = new Blob([xmlContent], { type: 'application/xml' });
                            selectedFileName = `${fileName}.xml`;
                            break;
    
                        case 'yml':
                            let ymlContent = "";
                            Object.keys(payload).forEach(key => {
                                ymlContent += `${key}: ${payload[key]}\n`;
                            });
                            blob = new Blob([ymlContent], { type: 'text/yaml' });
                            selectedFileName = `${fileName}.yml`;
                            break;
    
                        default:
                            blob = new Blob([JSON.stringify(payload, null, 2)], { type: 'application/json' });
                            selectedFileName = `${fileName}.json`;
                    }
    
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = selectedFileName;
                    document.body.appendChild(link);
                    link.click();
    
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    setDownloadFileId(null);
                    setFileName('');
                    setDownloadModal(false);
                } else {
                    fnShowSnackBar('Some error occurred while downloading the file, please try again!');
                }
            })
            .catch(error => {
                console.log(error);
                fnShowSnackBar('Some error occurred while downloading the file, please try again!');
            });
        } else {
            fnShowSnackBar('Please Select any File!');
        }
    };
    
    const fnOnClickDownload =()=>{
        setDownloadModal(true); 
        setDownloadFileId(selectedFile == "all" ? allFiles?.[0]?.id : selectedFile); 
        const selectedFileName = allFiles.find((f)=> f?.id == selectedFile)?.name;
        setFileName(selectedFile == "all" ? allFiles?.[0]?.name : selectedFileName); 
    };

    const fnOnClickExport =()=> {
        setExportModal(true);
        setExportFileId(selectedFile == "all" ? allFiles?.[0]?.id : selectedFile); 
        const selectedFileName = allFiles.find((f)=> f?.id == selectedFile)?.name;
        setFileName(selectedFile == "all" ? allFiles?.[0]?.name : selectedFileName); 
    };

    const fnExportFile = () => {
        const body = { project_id : projectId, file_id : exportFileId, language_id : selectedLangId, server_id : selectedServerId };  
        const checkValidation = projectId && exportFileId && selectedLangId && selectedServerId;
        if(checkValidation) {
            fileDeployToServer(body)?.unwrap()?.then((payload) => {
                if (payload?.success) {
                    setExportModal(false);
                    fnShowSnackBar('File Deployed Successfully!');
                }
            }).catch(()=> { fnShowSnackBar('Something went wrong please try again!')});
        } else {
            fnShowSnackBar('Must filled all fields!');
        } 
    };

    return {
        // params
        language,
        projectId,
        languageId,
        languageName,
        userRole,

        // project type
        isProjectInvited,
        isProjectPersonal,

        // apis data
        translations,
        keys,
        allFiles,
        allProjectFtps,
        fileFtpDetail,

        // isLoading apis
        isLoading,
        isLoadingAddTranslation,
        isLoadingUpdateTranslation,
        isLoadingFileDeploy,
        isLoadingDownloadTranslation,
        isLoadingFileFtpDetail, 
        isFetchingFileFtpDetail,

        // variables
        filesDetails,
        allFilesDropDown,
        projectName,
        projectLanguages,

        // all useStates 
        translationsForLanguage,
        filteredKeys,
        searchTerm,
        selectedFile,
        downloadModal,
        exportModal,
        downloadFileId,
        exportFileId,
        selectedServerId,
        selectedLangId,
        fileName,
        format,

        setTranslationsForLanguage,
        setFilteredKeys,
        setSearchTerm,
        setSelectedFile,
        setDownloadModal,
        setExportModal,
        setDownloadFileId,
        setExportFileId,
        setSelectedServerId,
        setSelectedLangId,
        setFileName,
        setFormat,

        // all functions
        fnOnSearch,
        fnOnClickFile,
        fnAddTranslation,
        fnUpdateTranslation,
        fnDownloadTranslations,
        fnOnClickDownload,
        fnOnClickExport,
        fnExportFile,


    }
}