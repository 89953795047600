import React, { useEffect, useRef, useState } from 'react'
import './View.css'
import { useGetKeysQuery } from '../../redux/storeApis';
import { EmptyLoader, LangSaver } from '../loader/Loader';
import { TRANSLATION_TYPES } from '../../constants/Index';
import { lineHeight, maxHeight } from '@mui/system';

const ViewTranslation = ({ allFiles, keyData, fnUpdateTranslation, fnAddTranslation, translationsForLanguage, projectId, index, isLoading, showTranslations, }) => {

    const textareaRef = useRef(null);
    const [selectedKeyId, setSelectedKeyId] = useState('');
    const [savingKeyId, setSavingKeyId] = useState(null);
    const { data: keys } = useGetKeysQuery(projectId);
    const keyId = keyData?.id;
    const fileId = keyData?.file_id;
    const keyParentId = keyData?.parent_id;
    const fileNameKey = allFiles?.find((file) => file?.id == fileId)?.name;
    const getParentKeyName = keys?.find((key) => key?.id == keyParentId)?.key;
    const isKeyHasChildKeys = !keyParentId && keys?.find((key) => key?.parent_id == keyId);
    const translationForThisKey = translationsForLanguage?.find(translation => translation?.key_id == keyId);

    const isAllTranslationsAvailable = keys?.length == translationsForLanguage?.length;
    const textAreaStyle = { width: '100%', maxWidth: '100%', marginRight: '48px', boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)", minHeight: '48px', lineHeight: '32px', padding: '14px 20px 12px 20px', height: "32px", maxHeight: '360px' };

    const [word, setWord] = useState(translationForThisKey?.text || '');

    useEffect(() => { setWord(translationForThisKey?.text || '') }, [translationForThisKey?.text]);

    useEffect(() => {
        if (textareaRef.current) {
            autoResize({ target: textareaRef.current });
        }
    }, [word, showTranslations]);

    const fnOnChange = (event, keyId) => {
        setWord(event.target.value);
        setSelectedKeyId(keyId);
    };

    const fnOnBlur = async () => {
        if (word && word?.trim() != translationForThisKey?.text?.trim() && !isLoading) {
            setSavingKeyId(keyId);
            try {
                let success = false;
                if (translationForThisKey) { success = await fnUpdateTranslation(projectId, keyId, word); }
                else { success = await fnAddTranslation(projectId, keyId, word); }
                if (success) { setSelectedKeyId(null); }
            } finally { setSavingKeyId(null); }
        };
    };

    const fnKeyPress = (e) => {
        if (e.key == 'Enter') {
            if (!isLoading) {
                fnOnBlur();
            }
        }
    };

    function autoResize(e) {
        e.target.style.height = '32px';  // Reset height to auto
        e.target.style.height = e.target.scrollHeight + 'px';  // Set height based on scroll height
    };

    return (
        <>
            {isKeyHasChildKeys ? <></>
                : (showTranslations == TRANSLATION_TYPES.missing && !translationForThisKey?.text) ?
                    <div className="term__input gap-[24px]" >

                        <div className='flex flex-col gap-[4px] min-w-[180px] max-w-[180px]'>
                            <h3 className='w-fit text-[12px] text-[var(--Primary)]' >{'File : ' + fileNameKey}</h3>
                            {keyParentId && getParentKeyName && <h3 style={{ maxWidth: '180px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className='p-[4px] py-[2px] bg-[#FFF] text-[11px] text-[var(--Primary)] w-fit'>
                                {'CONTEXT:' + getParentKeyName}
                            </h3>}
                        </div>

                        <div className="flex gap-[4px] w-full justify-between flex-col">
                            <h3 className="project__heading pl-1">{keyData?.key}</h3>
                            <div style={{ position: 'relative' }}>
                                <textarea
                                    ref={textareaRef}
                                    value={word}
                                    onChange={(event) => fnOnChange(event, keyId)}
                                    onBlur={() => fnOnBlur()}
                                    onKeyDown={(e) => fnKeyPress(e)}
                                    type="text"
                                    className="input__field"
                                    placeholder=""
                                    style={textAreaStyle}
                                />
                                {savingKeyId == keyId && (<LangSaver loaderStyle={{ position: 'absolute', top: -20, left: -52 }} />)}
                            </div>
                        </div>

                    </div>
                    : (showTranslations == TRANSLATION_TYPES?.missing && isAllTranslationsAvailable && index == 0) ? <EmptyLoader />
                        : (showTranslations == TRANSLATION_TYPES.all) && <div className="term__input gap-[24px]">

                            <div className='flex flex-col gap-[4px] min-w-[180px] max-w-[180px]'>
                                <h3 className='w-fit text-[12px] text-[var(--Primary)]' >{'File : ' + fileNameKey}</h3>
                                {keyParentId && getParentKeyName && <h3 style={{ maxWidth: '180px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className='p-[4px] py-[2px] bg-[#FFF] text-[11px] text-[var(--Primary)] w-fit'>
                                    {'CONTEXT:' + getParentKeyName}
                                </h3>}
                            </div>

                            <div className="flex gap-[4px] w-full justify-between flex-col">
                                <h3 className="project__heading pl-1">{keyData?.key}</h3>
                                <div style={{ position: 'relative' }}>
                                    <textarea
                                        ref={textareaRef}
                                        value={word}
                                        onChange={(event) => fnOnChange(event, keyId)}
                                        onBlur={() => fnOnBlur()}
                                        onKeyDown={(e) => fnKeyPress(e)}
                                        type="text"
                                        className="input__field"
                                        placeholder=""
                                        style={textAreaStyle}
                                    />
                                    {savingKeyId == keyId && (<LangSaver loaderStyle={{ position: 'absolute', top: -20, left: -52 }} />)}
                                </div>
                            </div>

                        </div>
            }
        </>
    );
}

export default ViewTranslation;