import React from 'react'
import DZText from '../text/DZText';
import { Tooltip } from '@mui/material';

export const DZOldBtn = ({
  children,
  className,
onClick,outline,solid,fullRadius,smallText, textStyle,btnIcon,toolTipText, simpleIcon, toolTipPlacement }) => {
  return (
    <Tooltip
      title={toolTipText}
      arrow
      disableInteractive
      placement={toolTipPlacement}>

      <div className={`
      ${className} 
      ${outline && 'bg-DBE2EF'} 
      ${fullRadius && 'rounded-full'} 
      ${simpleIcon && 'bg-F9F7F7 rounded-[10px]'} 
      ${solid && "bg-112D4E"} gap-[12px] py-[12px] px-[24px] flex items-center justify-center cursor-pointer`}
        onClick={onClick}
      >

        {btnIcon}

        <DZText
          className={textStyle}
          small={smallText}
          children={children}
        />

      </div>

    </Tooltip>
  )
}

const DZBtn = ({children, onClick, btnStyle, className, disabled = false}) => {
  const style = {...styles.btn, ...btnStyle};
  return (
    <button disabled={disabled} onClick={onClick} style={style} >
     <p style={className}> {children}</p>
    </button>
  )
}

export default DZBtn

const styles={
  btn:{
    color:'white', width:130,height:40, borderRadius: 5, marginTop: '30%', background: `var(--Primary)` 
  }
}
