import React, { useEffect, useState } from 'react'
import DZInput from '../../components/shared/inputField/DZInput';
import DZBtn from '../../components/shared/buttons/DZBtn';
import ViewUiBox from '../../components/views/ViewUiBox';
import { DeleteIcon } from '../../icons/Icons';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import DZModal from '../../components/modal/DZModal';
import { useGetAllUserRolesQuery, useGetInvitationsQuery, useGetProjectsQuery, useSendInvitationMutation,  } from '../../redux/storeApis';
import { EmptyLoader, Loading } from '../../components/loader/Loader';
import { Config, KEYS, META_TAGS } from '../../constants/Index';
import { getLocalStorage, setLocalStorage } from '../../components/localStorage';
import { useLocation } from 'react-router-dom';
import MetaTags from '../../components/seo/MetaTags';
import ViewLanguages from '../../components/views/ViewLanguages';

const ContributorPage = () => {

    const location = useLocation();
    const { fnShowSnackBar } = useSnackBarManager();
    const { data: allProjects } = useGetProjectsQuery();
    const { data : allUserRoles } = useGetAllUserRolesQuery();
    const [sendInvitation, { isLoading: isLoadingSendInvitation }] = useSendInvitationMutation();

    const defaultSelectedProjectId = getLocalStorage(KEYS.selected_project_id);

    const [inputData, setInputData] = useState({ email: '', project: null, language: null });

    const [projectLanguages, setProjectLanguages] = useState([]);
    const [inviteModal, setInviteModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ id: null, status: false });
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [isLoadingDeleteInvitation, setIsLoadingDeleteInvitation] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState(null);

    const { data: allInvitations, isLoading: isLoadingAllInvitations, isSuccess: isSuccessAllInvitations, refetch: refetchAllInvitations } = useGetInvitationsQuery(selectedProjectId);
    const isInvitationsExist = allInvitations?.length > 0;
    const isProjectExist = allProjects?.length > 0;
    const storedToken = localStorage.getItem(Config.userToken);

    useEffect(() => {      
        const projectId = defaultSelectedProjectId ?? allProjects?.[0]?.id;
        setSelectedProjectId(projectId);
        if(projectId && isProjectExist) { fnSetSelectedProjectLanguages(projectId) }
    }, [allProjects]);

    useEffect(()=>{ fnSetSelectedProjectLanguages(selectedProjectId); },[selectedProjectId]);

    const fnOnChange = (e, projectId) => {
        const { name, value } = e.target;
        setInputData(pre => ({ ...pre, [name]: value }));

        if (projectId) { fnSetSelectedProjectLanguages(projectId); setSelectedProjectId(projectId); }
    };

    const fnInviteMember = () => {
        
        const checkValidation = inputData?.email && inputData?.project && inputData?.language && selectedUserRole;

        if (checkValidation) {

            const body = { email: inputData?.email, project_id: inputData?.project, language_id: inputData?.language, role_id : selectedUserRole };
            sendInvitation(body).unwrap().then((payload) => {
                if (payload?.success) {
                    fnShowSnackBar('Invitation sent successfully!');
                    setInviteModal(false);
                    setInputData({ email: '', project: null, language: null });
                    setSelectedUserRole(null);
                    setSelectedProjectId(defaultSelectedProjectId);
                    fnSetSelectedProjectLanguages(defaultSelectedProjectId);
                }
            }
            ).catch((error) => {fnShowSnackBar('Something went wrong please try again!'); setInviteModal(false);});
        } else {
            if (projectLanguages?.length > 0) {
                fnShowSnackBar('Please fill in all fields.');
            } else {
                fnShowSnackBar('Add Language in your project first!');
                setTimeout(() => { setInviteModal(false); }, 1000);
            }
        }
    };

    const fnDeleteInvitation = async() => {
        setIsLoadingDeleteInvitation(true);
        const body = { id: deleteModal?.id, project_id: selectedProjectId };
        const url = `${Config.serverApiUrl}delete-invitations?id=${body.id}&project_id=${body.project_id}`;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${storedToken}`);
        const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow', };
        await fetch(url, requestOptions)
            .then(response => {
                console.log('res', response);
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                if (data?.success) {
                    refetchAllInvitations();
                    setIsLoadingDeleteInvitation(false);
                    setDeleteModal({ ...deleteModal, status: false });
                    fnShowSnackBar('Invitation removed successfully!');
                } else {
                    setIsLoadingDeleteInvitation(false);
                }
            })
            .catch(error => {
                console.error('Error deleting invitation:', error);
                setIsLoadingDeleteInvitation(false);
                fnShowSnackBar('Failed to delete invitation');
            });
    };

    const fnSetSelectedProjectLanguages =(projectId)=>{
        const selectedProjectLanguages = allProjects?.find((project) => project?.id == projectId)?.languages;
        setProjectLanguages(selectedProjectLanguages);
        setInputData({...inputData, language: selectedProjectLanguages?.[0]?.language_id, project : projectId});
    };

    return (
        <>
            <MetaTags href={location.pathname} pageName={META_TAGS.contributors} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />
            
            <ViewUiBox projectName={'Contributors'} onClickAddBtn={() => setInviteModal(true)}>

                {isProjectExist && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 style={{ fontSize: 18 }}>{'Select Project'}</h3>
                    <select value={selectedProjectId} style={{ width: '20%' }} onChange={(e) => { setSelectedProjectId(e.target.value); setLocalStorage(KEYS.selected_project_id, e.target.value) }}>
                        {allProjects?.map((project) => {
                            const projectId = project?.id;
                            const projectName = project?.name;
                            return (
                                <option value={projectId}>{projectName}</option>
                            )
                        })}
                    </select>
                </div>}

                {isLoadingAllInvitations ? <Loading />
                    : isInvitationsExist ? allInvitations?.map((invitation) => {
                        return (
                            <div key={invitation?.id} className="project__div">
                                <h3 style={{ cursor: 'pointer', }} className="w-[240px] max-lg:w-[200px] max-sm:w-[160px] truncate text-[var(--primary-700)]">{invitation?.email}</h3>
                                <h3 style={{ cursor: 'pointer', }} className="w-[240px] max-lg:w-[200px] max-sm:w-[160px] ]">{invitation?.language?.code}</h3>
                                <h3 style={{ cursor: "pointer" }} className={`w-[240px] max-lg:w-[200px] max-sm:w-[160px] ${ invitation?.status === 1 ? "text-green-500" : "text-orange-500" }`}>{ invitation?.status == 1 ? "Accepted" : "Pending" }</h3>
                                <div className="flex items-center gap-[24px] max-lg:gap-[12px]">
                                    {/* <h3>{invitation?.status == 1 ? 'invitation accepted' : 'invitation pending'}</h3> */}
                                    <div onClick={() => setDeleteModal({ id: invitation?.id, status: true })} className="add__btn">
                                        <DeleteIcon />
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <EmptyLoader />
                }

            </ViewUiBox>

            <DZModal open={inviteModal} disabled={isLoadingSendInvitation} onKeyDown={fnInviteMember} onClose={() => setInviteModal(false)}>
                <div className="login__form" style={{ background: 'var(--Light)', zIndex: '9', boxShadow: '0 4px 34px 2000px rgba(0, 0, 0, 0.1)' }}>

                    <div className="form__heading">
                        Invite Contributor
                    </div>

                    <div className="form__fields">

                        <DZInput id="email" name="email" type="email" placeholder="Email" className={'input__field'} onChange={(e) => { fnOnChange(e); }} />

                        <select name="project" id="project" className='input__field' value={selectedProjectId} onChange={(e) => { fnOnChange(e, e.target.value) }} >

                            <option value="" hidden >
                                Select Project
                            </option>
                            {allProjects?.map((project) => <option value={project?.id} >{project?.name}</option>)}

                        </select>

                        { projectLanguages?.length > 0 && <select name="language" id="language" className='input__field' value={inputData?.language} onChange={(e) => fnOnChange(e)}>

                            <option value="" hidden >
                                Select Langauge
                            </option>
                            {projectLanguages?.map((language) => <option value={language?.lang?.id} >{language?.lang?.name}</option>)}

                        </select> }

                        <select className='input__field' name='role' id='role' value={selectedUserRole} onChange={(e)=> setSelectedUserRole(e.target.value)} >
                            
                            <option value="" hidden>
                                Select Role
                            </option>

                            {allUserRoles?.data?.map((role)=> <option value={role?.id} >{role?.name}</option> )}

                        </select>

                    </div>

                    <DZBtn
                        disabled={isLoadingSendInvitation}
                        onClick={() => fnInviteMember()}
                        btnStyle={{ marginTop: 0, width: '100%', borderRadius: 16, height: 50, fontSize: 20, }}
                        children={isLoadingSendInvitation ? "Loading..." : "Invite"}
                    />

                </div>
            </DZModal>

            <DZModal open={deleteModal.status} disabled={isLoadingDeleteInvitation} onClose={() => setDeleteModal({ id: null, status: false })} onKeyDown={fnDeleteInvitation}>
                <h1>{'Are you sure to want to remove this contributor ?'}</h1>
                <DZBtn disabled={isLoadingDeleteInvitation} onClick={() => fnDeleteInvitation()} variant="contained"> 
                    { isLoadingDeleteInvitation ? 'Loading...' : 'Remove'} 
                </DZBtn>
            </DZModal>

        </>
    )
}

export default ContributorPage;