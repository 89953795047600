import React, { useState } from 'react'
import ViewUiBox from '../../components/views/ViewUiBox'
import { TextField } from '@mui/material';
import { DeleteIcon, EditIcon, RightArrowIcon } from '../../icons/Icons';
import DZBtn from '../../components/shared/buttons/DZBtn';
import DZModal from '../../components/modal/DZModal';
import { useFilePageManager } from '../../lib/customHooks/useFilePageManager';
import { EmptyLoader, Loading } from '../../components/loader/Loader';
import { useGetUserRole } from '../../lib/customHooks/useGetUserRole';
import MetaTags from '../../components/seo/MetaTags';
import { useLocation } from 'react-router-dom';
import { useNavigationManager } from '../../lib/customHooks/useNavigationManager';

const FilePage = () => {

    const location = useLocation();

    const { file, fileId, projectId, fileName, projectName, userRole, projectDetails, keys, fileKeys, projectLanguages, isExistProjectLanguages, addKeyModal, isLoading,
        delKeyModal, updateKeyModal, translationModal, inputKey, selectedProjectLanguageId, search, filterKeys, isLoadingAddKey, isLoadingDeleteKey, isLoadingUpdateKey,
        setAddKeyModal, setDelKeyModal, setUpdateKeyModal, setTranslationModal, setInputKey, setSelectedProjectLanguageId, setSearch, setFilterKeys,
        fnAddKey, fnDeleteKey, fnUpdateKey, fnInputChange, fnClickImportBtn, fnInputSearch, fnFilterKeysData } = useFilePageManager();

    const { fnGoBack } = useNavigationManager();

    const isExistKeys = filterKeys?.length > 0;

    const { isUserOwner, isUserTranslator } = useGetUserRole(userRole);
    const [parentKey, setParentKey] = useState('');

    return (
        <div className="flex items-center justify-center my-[6vw]">

            <MetaTags href={location.pathname} pageName={`Byte Translate | ${projectName ?? 'Project Files'}`} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

            <div className='flex flex-col items-center gap-[24px] max-[1100px]:px-[6vw] w-full px-[16vw]'>

                {projectName && (
                    <div className="flex items-center justify-between w-full">

                        <div onClick={() => fnGoBack()} className="flex items-center gap-[20px] w-fit max-w-[1200px] cursor-pointer group">

                            <RightArrowIcon className={'cursor-pointer w-[32px] h-[32px] group-hover:translate-x-[-10px] transition'} iconColor={'var(--Primary)'} />

                            <span className='text-[var(--Primary)] text-[24px] w-fit'>
                                {projectName}
                            </span>

                        </div>

                    </div>
                )}

                <ViewUiBox className="w-full" onClickAddBtn={() => setAddKeyModal(true)} projectName={fileName} topSpacing={true} showAddIcon={!isUserTranslator} >
                    <input className='input__field' placeholder='Search word' value={search} onChange={(event) => fnInputSearch(event)} style={{ maxWidth: '100%', minWidth: '100%', }} />

                    <h1 onClick={() => fnClickImportBtn()} style={{ textAlign: 'end', textDecoration: 'underline', cursor: 'pointer' }}>
                        {'import'}
                    </h1>

                    <div className="flex flex-col gap-[20px] overflow-y-scroll max-h-[60dvh]" >
                        {
                            isLoading ? <Loading />
                                : isExistKeys ?
                                    filterKeys?.map((key) => {
                                        const getParentKeyName = keys?.find((item) => item?.id == key?.parent_id)?.key;
                                        return (
                                            <>

                                                <div className="project__div">

                                                    <div>
                                                        <h3 className="">{key?.key}</h3>
                                                        {!key?.parent_id && <h3 className='p-[4px] py-[2px] bg-[var(--Primary)] text-[11px] text-[#FFF] w-fit'>{'Parent Key'}</h3>}
                                                        {key?.parent_id && <h3 className='p-[4px] py-[2px] bg-[#FFF] text-[11px] text-[var(--Primary)] w-fit'>{getParentKeyName}</h3>}
                                                    </div>

                                                    <div className="flex gap-[20px]">

                                                        <div className="add__btn" onClick={() => { setInputKey(key?.key); setParentKey(getParentKeyName); setUpdateKeyModal({ status: true, id: key?.id }) }}>
                                                            <EditIcon />
                                                        </div>

                                                        <div className="add__btn" onClick={() => setDelKeyModal({ status: true, id: key?.id })}>
                                                            <DeleteIcon />
                                                        </div>

                                                    </div>

                                                </div>

                                            </>
                                        )
                                    })
                                    : <EmptyLoader />
                        }
                    </div>

                </ViewUiBox>

                <DZModal open={addKeyModal} disabled={isLoadingAddKey} onKeyDown={fnAddKey} onClose={() => setAddKeyModal(false)} >
                    <TextField id="outlined-basic" value={inputKey} onChange={(event) => setInputKey(event.target.value)} label="Key" variant="outlined" style={{ width: '100%' }} />
                    <DZBtn disabled={isLoadingAddKey} onClick={() => fnAddKey()} variant="contained">
                        {isLoadingAddKey ? 'Loading...' : 'Add Key'}
                    </DZBtn>
                </DZModal>

                <DZModal open={updateKeyModal.status} disabled={isLoadingUpdateKey} onKeyDown={fnUpdateKey} onClose={() => { setUpdateKeyModal({ status: false, id: null }); setInputKey(''); setParentKey('') }} >
                    { parentKey && <h1 className='mb-[12px]' >{`Parent key (${parentKey})`}</h1>}
                    <TextField id="outlined-basic" value={inputKey} onChange={(event) => setInputKey(event.target.value)} label="Key" variant="outlined" style={{ width: '100%' }} />
                    <DZBtn disabled={isLoadingUpdateKey} onClick={() => fnUpdateKey()} variant="contained">
                        {isLoadingUpdateKey ? 'Loading...' : 'Update Key'}
                    </DZBtn>
                </DZModal>

                <DZModal open={delKeyModal.status} disabled={isLoadingDeleteKey} onKeyDown={fnDeleteKey} onClose={() => setDelKeyModal({ ...delKeyModal, status: false })} >
                    <h1>{'Are you sure to want to delete this Key ?'}</h1>
                    <DZBtn disabled={isLoadingDeleteKey} onClick={() => fnDeleteKey()} variant="contained">
                        {isLoadingDeleteKey ? 'Loading...' : 'Delete Key'}
                    </DZBtn>
                </DZModal>

                <DZModal open={translationModal} onKeyDown={fnInputChange} onClose={() => setTranslationModal(false)} >
                    {isExistProjectLanguages && <h1 style={{ color: 'black', fontSize: 18, bottom: 5 }} >{'Select Language'}</h1>}
                    {projectLanguages?.map((language) => {
                        const isSelectedLang = selectedProjectLanguageId == language?.language_id;
                        return (
                            <div
                                style={{ color: isSelectedLang ? 'white' : 'black', background: isSelectedLang ? `linear-gradient(140deg, var(--Primary), var(--Dark))` : 'transparent', margin: 5, borderWidth: 1, paddingLeft: 5, padding: 3, borderRadius: 5, cursor: 'pointer' }}
                                onClick={() => setSelectedProjectLanguageId(language?.language_id)}
                            >
                                {language?.lang?.name}
                            </div>
                        )
                    })}
                    {selectedProjectLanguageId && <input style={{ marginTop: '15%' }} type='file' className='cursor-pointer' onChange={(event) => { fnInputChange(event) }} />}
                </DZModal>

            </div>
        </div>
    )
}

const styles = {
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        background: `linear-gradient(140deg, var(--Dark), var(--Light))`,
        borderRadius: 2,
        boxShadow: 4,
        p: 4,
        outline: 'none'
    },
    keyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 10,
        padding: 10,
        backgroundColor: 'grey',
        color: 'white'
    }
}

export default FilePage