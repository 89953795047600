import React, { useEffect, useState } from "react";
import {
  useAcceptInvitationMutation,
  useGetInvitationInfoQuery,
} from "../../redux/storeApis";
import { useNavigate } from "react-router-dom";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { ROUTES } from "../../reactRoute/RouteConstants";
import DZInput from "../../components/shared/inputField/DZInput";
import { Config, SNACK_BAR_MSG } from "../../constants/Index";
import { useDispatch } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { setLocalStorage } from "../../components/localStorage";
import InvitationDetails from "./InvitationDetails";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { Loading } from "../../components/loader/Loader";
import { Height } from "@mui/icons-material";

const InvitationContributePage = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [acceptInvitation, { isLoading: isLoadingAcceptInvitation }] = useAcceptInvitationMutation();

  const [slug, setSlug] = useState("");
  // const [name, setName] = useState('');
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');

  const {
    data: invitationInfo,
    error: invitationError,
    isLoading: isLoadingInvitationInfo,
  } = useGetInvitationInfoQuery(slug, { skip: !slug });

  const { fnShowSnackBar } = useSnackBarManager();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const slugFromUrl = params.get("slug");
    setSlug(slugFromUrl);
  }, []);

  const fnAcceptInvitation = () => {
    const checkValidation = slug;

    if (checkValidation) {
      const email = invitationInfo?.data?.info?.inviatation?.email;
      if (!email) {
        fnShowSnackBar("Email not available");
        return;
      }

      const body = { slug };
      acceptInvitation(body)
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            fnShowSnackBar("Invitation accepted!");
            navigate(ROUTES.home);
          } else {
            fnShowSnackBar(payload?.message);
          }
        })
        .catch((error) => {
          const isUserNotExist = error?.data?.status == 302;
          const message = `${error?.data?.message}! ${isUserNotExist && 'first create your account then accept the invitation'}`
          fnShowSnackBar(message);
          if (isUserNotExist) {
            navigate(ROUTES.signup)
          }
        });
    }
  };

  return (
    // <div
    //   className=""
    //   style={{
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     height: "100vh",
    //     flexDirection: "column",
    //   }}
    // >
    //   {invitationInfo && <InvitationDetails invitationInfo={invitationInfo} />}
    //   {/* 
    //   <DZInput onChange={(e) => setName(e.target.value)} placeholder="Name" style={{ marginTop: 45, width: 300 }} />

    //   <DZInput onChange={(e) => setPassword(e.target.value)} placeholder="Password" style={{ marginTop: 45, width: 300 }} />

    //   <DZInput onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm password" style={{ marginTop: 15, width: 300 }} /> */}

    //   <button
    //     onClick={fnAcceptInvitation}
    //     style={{
    //       background: "#424874",
    //       borderRadius: 3,
    //       color: "white",
    //       marginTop: 45,
    //       padding: 12,
    //       width: 300,
    //     }}
    //   >
    //     {isLoadingAcceptInvitation ? "Loading..." : "Accept Invitation"}
    //   </button>

    //   <button
    //     style={{
    //       background: "white",
    //       borderRadius: 3,
    //       color: "red",
    //       marginTop: 15,
    //       padding: 11,
    //       borderWidth: 1,
    //       width: 300,
    //       borderColor: "red",
    //     }}
    //   >
    //     Reject Invitation
    //   </button>
    // </div>
    <div className="flex items-center justify-center h-[100%] w-[100%]">
      <div className="w-[500px] max-sm:w-[350px] bg-[white] shadow rounded-xl flex flex-col items-center gap-10 p-[24px]">
        {invitationInfo ? <InvitationDetails invitationInfo={invitationInfo} /> : <Loading loaderStyle={{ height: '120px', width: '120px' }} containerStyle={{ minHeight: '100px' }} />}
        <div className="flex items-center justify-around gap-[18px] w-full">
          <DZBtn btnStyle={{ marginTop: 0, width: '100%', backgroundColor: 'white', border: '1px solid var(--Primary)', color: 'var(--Primary)' }} >Reject</DZBtn>
          <DZBtn onClick={fnAcceptInvitation} btnStyle={{ marginTop: 0, width: '100%' }} >
            {isLoadingAcceptInvitation ? "Loading..." : "Accept"}
          </DZBtn>
        </div>
      </div>
    </div>
  );
};

export default InvitationContributePage;
