import React from 'react';
import styled from 'styled-components';

const InvitationDetailsContainer = styled.div`
  border-radius: 5px;
  display : flex;
  flex-direction : column;
  align-items : center;
  gap : 14px
`;

const Title = styled.h2`
  text-align : center;
`;

const DetailItem = styled.p`
  text-align: center;
    margin-top: 10px
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const InvitationDetails = ({ invitationInfo }) => {
  if (!invitationInfo || !invitationInfo.data || !invitationInfo.data.info) {
    return null;
  }
  console.log("invitationInfo", invitationInfo);
  const { inviatation, language, user } = invitationInfo.data.info;

  return (

    <InvitationDetailsContainer>
      <img src="/images/logo.png" className="w-[40px] h-[40px] object-contain" />

      <div>
        <Title>Invitation details from ByteTranslate </Title>
        <DetailItem>
          <Strong>Project:</Strong> {inviatation?.project?.name}
        </DetailItem>
        <DetailItem>
          <Strong>Language:</Strong> {language?.name}
        </DetailItem>
        <DetailItem>
          <Strong>Invited By:</Strong> {inviatation?.invited_by}
        </DetailItem>
      </div>
    </InvitationDetailsContainer>
  );
};

export default InvitationDetails;