import React, { useEffect, useRef, useState } from 'react'
import { InfinityLoader } from '../../components/loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';
import { Element, scroller, animateScroll as scroll, scrollSpy } from 'react-scroll';
import MetaTags from '../../components/seo/MetaTags';
import './header.css'

const Header = () => {
    const navigate = useNavigate();
    const [isShowMobileBar, setIsShowMobileBar] = useState(false);
    const mobileBar = useRef(null);
    const [activeTab, setActiveTab] = useState('Home');
    const location = useLocation();

    useEffect(() => {
        const handClickOutside = (event) => {
            if (mobileBar.current && !mobileBar.current.contains(event.target)) {
                setIsShowMobileBar(false);
            }
        };

        document.addEventListener("mousedown", handClickOutside);
        return () => { document.removeEventListener("mousedown", handClickOutside); };

    }, []);

    useEffect(() => {
        // const handleScroll = () => {
        //     if (window.scrollY === 0) {
        //         setActiveTab('Home');
        //     }
        // };

        // window.addEventListener('scroll', handleScroll);

        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);

    const scrollTo = (section) => {
        scroller.scrollTo(section, {
            duration: 200,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    const handleTabClick = (section) => {
        if (isShowMobileBar) {
            setIsShowMobileBar(false);
        }
        setActiveTab(section);
        scrollTo(section);
    };

    const fnActiveTabClassName = (tabName) => {

        // return `px-[32px] py-[14px] ${activeTab == tabName 
        //     ? 'bg-[var(--primary-700)] text-[var(--primary-100)] activeTab' 
        //     : 'inactiveTab'} rounded-[18px] cursor-pointer`;

        console.log('routeName=' + location.pathname)
        console.log('tabName=' + tabName)

        return `px-[32px] py-[14px] ${location.pathname == tabName
            ? 'bg-[var(--primary-700)] text-[var(--primary-100)] activeTab'
            : 'text-[var(--Primary)] inactiveTab'} rounded-[18px] cursor-pointer`;
    }

    const MobileNavbar = () => {
        return (
            <div className="flex items-center justify-center inset-0 fixed z-[9] bg-[#11111130]">
                <div className="flex items-center flex-col  justify-center bg-[var(--primary-100)] p-[20px] rounded-[20px]" ref={mobileBar}>
                    <span onClick={() => { navigate(ROUTES.home) }} className={fnActiveTabClassName(ROUTES.home)}>
                        Home
                    </span>
                    <span onClick={() => { navigate(ROUTES.about) }} className={fnActiveTabClassName(ROUTES.about)}>
                        About
                    </span>
                    <span onClick={() => { navigate(ROUTES.features) }} className={fnActiveTabClassName(ROUTES.features)}>
                        Features
                    </span>
                    <span onClick={() => { navigate(ROUTES.contact) }} className={fnActiveTabClassName(ROUTES.contact)}>
                        Contact
                    </span>
                </div>
            </div>
        )
    };


    return (

        <>
            <div className="blob__pink effect__pink w-[240px] h-[240px] z-0" />

            <div className="blob__blue effect__blue  h-[240px] max-sm:hidden z-0" />
            <div className="navbar__anime flex items-center justify-between z-[1] relative m-[24px] px-[24px] py-[15px] bg-[var(--primary-100)] rounded-[10px]">

                <div className="lg:w-[181px] w-fit" onClick={() => navigate(ROUTES.home)}>
                    <img src="images/logo.png" className="cursor-pointer" alt="" />
                </div>

                <div className="flex items-center max-lg:hidden">
                    <span className={fnActiveTabClassName(ROUTES.home)} onClick={() => navigate(ROUTES.home)}>
                        Home
                    </span>

                    {/* <span className={fnActiveTabClassName('About')} onClick={() => handleTabClick('About')}> */}
                    <span className={fnActiveTabClassName(ROUTES.about)} onClick={() => navigate(ROUTES.about)}>
                        About
                    </span>

                    <span className={fnActiveTabClassName(ROUTES.features)} onClick={() => navigate(ROUTES.features)}>
                        Features
                    </span>

                    <span className={fnActiveTabClassName(ROUTES.contact)} onClick={() => navigate(ROUTES.contact)}>
                        Contact
                    </span>

                </div>

                <div className="flex items-center gap-[20px]">

                    <svg onClick={() => setIsShowMobileBar(true)} className='lg:hidden cursor-pointer' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#D0006E"} fill={"none"}>
                        <path d="M10 5L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4 12L20 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4 19L14 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <span onClick={() => navigate(ROUTES.signup)} className="text-[var(--primary-700)] cursor-pointer">
                        Sign Up
                    </span>

                    <span onClick={() => navigate(ROUTES.login)} className="btn">
                        Login
                    </span>

                </div>
            </div > 
                {
                    isShowMobileBar &&
                    <MobileNavbar />
                }
        </>
    );
};

export default Header;