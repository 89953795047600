import React from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styled from 'styled-components';
import { useGetFacebookLoginRouteQuery, useHandleFacebookLoginMutation } from "../../redux/storeApis";

const FacebookButton = styled.button`
  background-color: #3b5998;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #2d4373;
  }
  & svg {
    margin-right: 8px;
  }
`;

const Facebook = () => {
//   const { data: facebookLoginUrl } = useGetFacebookLoginRouteQuery(); // Get the Facebook login URL
  const [handleFacebookLogin, { isLoading }] = useHandleFacebookLoginMutation(); 

  const responseFacebook = async (response) => {
    if (response.accessToken) {
      await handleFacebookLogin(response); 
    }
  };

  return (
    <FacebookLogin
      appId="413592958135232"
      autoLoad={false}
      callback={responseFacebook}
      render={(renderProps) => (
        <FacebookButton onClick={renderProps.onClick} disabled={isLoading}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
            />
          </svg>
          {isLoading ? 'Loading...' : 'Continue with Facebook'}
        </FacebookButton>
      )}
    />
  );
};

export default Facebook;