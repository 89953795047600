import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserManager } from "../lib/customHooks/useUserManager";
import { ROUTES } from "./RouteConstants";

function ProtectedRoutes() {
  const { isLoggedInUser } = useUserManager();

  if (!isLoggedInUser) {
    return <Navigate to={ROUTES.login} replace />;
  }
  return <Outlet />;
}

export default ProtectedRoutes;
