import React, { useEffect, useState } from 'react'
import DZInput from '../../components/shared/inputField/DZInput'
import DZBtn from '../../components/shared/buttons/DZBtn'
import { useGetUserDataQuery, useUpdateUserDataMutation } from '../../redux/storeApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import MetaTags from '../../components/seo/MetaTags';
import { META_TAGS } from '../../constants/Index';

const ProfilePage = () => {

    const navigate = useNavigate();
    const location = useLocation();    

    const { data: userData, } = useGetUserDataQuery();
    const [updateUserData, { isLoading }] = useUpdateUserDataMutation();
    const { fnShowSnackBar } = useSnackBarManager();

    const [formData, setFormData] = useState({ name: "", email: '', password: '' });

    const variable = false;

    useEffect(() => {
        console.log('useEffect with ');
        if (userData) {
            const userDetails = userData?.data?.user;
            setFormData({ name: userDetails?.name, email: userDetails?.email, });
        }
    }, [userData]);

    const handleInputChange = (e) => {
        const { name, value } = e?.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const fnUpdate = () => {
        const body = { name: formData?.name, email: formData?.email, }
        updateUserData(body).unwrap().then((payload) => {
            if(payload?.success){      
                fnShowSnackBar('Profile Updated');
                // navigate(ROUTES.dashboard);
            }
        }).catch(error => {
            console.error('Error updating user data:', error);
            fnShowSnackBar('Something wents wrong, Please try again!');

        });

    };

    const fnKeyPress = (e) => {
        if (e?.key == 'Enter') {
          fnUpdate();
        }
      };

    return (
        <div className="login__form">

            <MetaTags href={location.pathname} pageName={META_TAGS.profile} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

            <div className="form__heading">
            Edit <span>Profile</span>
            </div>

            <div className="form__fields" onKeyDown={fnKeyPress}>
            <DZInput id="name" name="name" type="text" placeholder="Enter your Name" value={formData?.name} onChange={handleInputChange} className={'input__field'} />
            <DZInput id="email" name="email" type="email"  disabled={true} value={formData?.email}  className={'input__field'} />
            {/* <DZInput id="password" name="password" type="password"   value={formData?.password} className={'input__field'} /> */}
            </div>

            <DZBtn btnStyle={{ marginTop: 0, width: '100%', borderRadius: 16, height: 50, fontSize: 20, }} onClick={fnUpdate} children={isLoading ? 'Loading...' : "Update"} />

        </div>
    );
}

export default ProfilePage;