import React from 'react'
import { InfinityLoader } from '../../components/loader/Loader';
import { useLocation } from 'react-router-dom';
import { Element, scroller, animateScroll as scroll, scrollSpy } from 'react-scroll';
import MetaTags from '../../components/seo/MetaTags';
import './contact.css'
import Header from '../../components/header/Header';
import { META_TAGS } from '../../constants/Index';

const Contact = () => {
  const location = useLocation();

  return (
    <div className="bg-[#F3EDF7] overflow-x-hidden relative min-h-screen">

      <MetaTags href={location.pathname} pageName={META_TAGS.contact} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

      <Header />

      {/* contact element here */}
      <Element name="Contact">
      <div className='flex items-center gap-[15px] flex-col pt-[64px] pb-[64px] px-[64px] max-sm:px-[24px] z-[1 relative]'>
        <h1 className="heading1 text-[var(--neutral-700)] max-[1100px]:text-center">
          <span className='text-[var(--primary-700)]'>Conatct us</span>
        </h1>
        <p className='text-[var(--neutral-700)] max-w-[1090px] text-center'>
          Our dedicated customer support team is available around the clock to assist you with any inquiries or issues you may have. You can reach out to us via:
          <h5 className="text-[var(--neutral-500)] max-[1100px]:text-center">
            <span className='text-[var(--primary-700)]'>  Email:</span><span> info@danzeetech.com</span>
          </h5>

        </p>
      </div>
    </Element>
     
    </div>
  );
};

export default Contact;